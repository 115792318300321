import { Box, Button, Dialog, Typography } from '@mui/material'

import { useTexts } from '../../../texts'

const styles = {
  root: {
    '& .MuiPaper-root': {
      alignSelf: {
        xs: 'flex-start',
        md: 'center',
      },
      rowGap: 1.5,
      width: '100vw',
      maxWidth: (theme) => ({
        xs: '100vw',
        md: theme.spacing(23.4375),
      }),
      pt: 2,
      px: 2,
      pb: 2.5,
      height: 'auto',
      borderTopLeftRadius: (theme) => ({ md: theme.spacing(2) }),
      borderTopRightRadius: (theme) => ({ md: theme.spacing(2) }),
      borderBottomLeftRadius: (theme) => theme.spacing(2),
      borderBottomRightRadius: (theme) => theme.spacing(2),
    },
  },
  actions: {
    display: 'flex',
    columnGap: 1,
  },
}

const LogoutDialog = ({ isOpen, onClickLogout, onCancel }) => {
  const texts = useTexts()

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      sx={styles.root}
    >
      <Typography id='alert-dialog-title' variant='h4'>
        {texts.getLogoutTitleLabel()}
      </Typography>
      <Box sx={styles.actions}>
        <Button fullWidth onClick={onCancel}>
          {texts.getCancelAction()}
        </Button>
        <Button fullWidth color='secondary' onClick={onClickLogout}>
          {texts.getConfirmAction()}
        </Button>
      </Box>
    </Dialog>
  )
}

export default LogoutDialog
