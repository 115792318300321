export const getTexts = () => {
  const getBalanceTopUpLabel = () => 'Recargar saldo'
  const getRegisterTitleLabel = () => 'Una cuenta, cero suscripciones.'
  const getBalanceTopUpPopularLabel = () => 'POPULAR'
  const getBalanceTopUpAlertTitleLabel = () => 'Sin caducidad.'
  const getBalanceTopUpAlertTextLabel = () =>
    'Sin caducidad. Puedes usar tu saldo cuando quieras.'
  const getGenericContinueAction = () => 'Continuar'
  // const integratedMediaText =
  //   'El Mundo, El País, La Vanguardia, El Español, Cinco Días y Expansión'

  return {
    getPayperSlogan: () => 'Una cuenta, cero suscripciones.',
    getAccessTitle: () => 'Accede a Payper',
    getAccessWithPromotionTitle: (partnerName) =>
      `Disfruta gratis de la mejor prensa premium gracias a <b>${partnerName}</b>. <br/><br/>Para continuar, accede a Payper de forma gratuita:`,
    getAccessWithInvitationWarning: () =>
      'Si ya tienes cuenta de Payper, accede con tu cuenta de Google, Apple o con tu email.<br/><br/><b>Si todavía no estás registrado, no podrás acceder a Payper sin una invitación.</b>',
    getAccessWithInvitationError1: () =>
      'Lo sentimos, no es posible el registro en Payper sin una invitación.',
    getAccessWithInvitationError2: () => 'Contáctanos en ',
    getAccessWithInvitationError3: () =>
      ' si estás interesado en ofrecer Payper a tus clientes o empleados.',
    getContinueLabel: () => 'Continuar',
    getLoginTitle: () => 'Iniciar sesión',
    getUserDataTitle: () => '¿Quién hay ahí?',
    getChangeEmailAction: () => 'Usar otro correo',
    getAccessWithSocialLoginEmailError: () =>
      'Este correo se ha usado para un registro a través de cuenta Google o Apple. Cambia tu correo de acceso o usa uno de los botones de arriba para acceder con este correo.',

    getError: () => 'Se ha producido un error',
    getEmailLabel: () => 'Correo electrónico',
    getPasswordLabel: () => 'Contraseña',
    getNameLabel: () => 'Nombre',
    getFamilyNameLabel: () => 'Apellidos',
    getPhoneLabel: () => 'Teléfono',
    getInfoLinkLabel: () => '+ Info',
    getNoResultsLabel: () => 'No se han encontrado resultados',
    getUnexpectedError: () => 'Algo fue mal, inténtelo de nuevo más tarde.',
    getContactUsOnPersistentError1: () => ' Contacta con nosotros',
    getContactUsOnPersistentError2: () => ' si el problema persiste.',
    getLabelRequiredFields: () => 'Todos los campos son obligatorios',
    getErrorRequired: () => 'Campo obligatorio',
    getErrorOnlyLetters: () => 'El campo sólo puede contener letras',
    getErrorBankCardGeneral: () =>
      'Se ha producido un error al iniciar el proceso de registro',
    getErrorBankCardPreauth: () =>
      'Se ha producido un error durante la preautorización del registro. ',
    getErrorEmail: () => 'Correo inválido',
    getErrorPhone: () =>
      'Teléfono inválido. Por favor, introduzca las 9 cifras de su número de teléfono',
    getErrorPhoneWithMask: () =>
      'Teléfono inválido. No olvide añadir el prefijo del país, +34 para España.',
    getErrorPassword: () => 'Contraseña inválida',
    getErrorCode: () => 'Código inválido',
    getErrorConditionsAccepted: () => 'Debes aceptar las condiciones legales',
    getSaveSucceed: () => 'Los cambios se han guardado correctamente',
    getSuccessLabel: () => '¡Enhorabuena!',
    getCloseAction: () => 'Cancelar y cerrar',
    getUserDataLabel: () => 'Datos de acceso',
    getUserBankCardLabel: () => 'Datos de tu tarjeta',
    getBalanceLabel: () => 'Mi saldo',
    getUserLogoutAction: () => 'Cerrar sesión',
    getConfirmAction: () => 'Sí',
    getCancelAction: () => 'No',
    goBack: () => 'Volver',
    getGoBack: () => 'Volver atrás',
    getGoToAccount: () => 'Volver a mi cuenta',
    getTopUpBalanceSuccessLabel: () => 'Has recargado tu monedero',
    getOptionalLabel: () => 'Opcional',
    getBankCardNumberLabel: () => 'Número de tarjeta',
    getBankCardMontLabel: () => 'Mes',
    getBankCardYearLabel: () => 'Año',

    getPurchaseClaim: () => 'A esta te invitamos nosotros',
    getPurchaseByPartnerClaim: () => 'Noticia cortesía de ',

    getHomeTabsAriaLabel: () => 'Pestañas de la Home',
    getBreadcrumbsHomeLabel: () => 'Página principal',
    getBreadcrumbsPersonalDataEditionLabel: () => 'Actualizar datos de acceso',
    getBreadcrumbsBankCardEditionLabel: () => 'Actualizar datos de la tarjeta',
    getBreadcrumbsBankCardIntroLabel: () => 'Introducir datos de la tarjeta',
    getBreadcrumbsTopUpBalanceLabel: () => 'Recargar monedero',

    getLatestNewsTitle: () => 'Para ti',
    getAssociatedMediaTitle: () => 'Medios disponibles',
    getAssociatedMediaSubtitle: () =>
      'Selecciona el medio en el que quieras leer sin suscripciones:',
    getPartnerBenefitsClaim: (partnerName) =>
      `${partnerName} te ofrece un <b>servicio exclusivo de prensa</b>, con acceso a los periódicos más relevantes del país, <b>sin coste adicional para ti</b>.`,
    getBenefitsClaim: () =>
      'Además, aprovecha los beneficios de la tecnología Payper:',
    getSummaryTitle: () => 'Resumen',
    getSummaryClaim: () =>
      '¿No tienes tiempo de leer la noticia? Tienes un resumen.',
    getCoverageTitle: () => 'Cobertura',
    getCoverageClaim: () =>
      '¿Qué otros puntos de vista hay sobre esta noticia? Compruébalo tú mismo en el informe de cobertura.',
    getContextTitle: () => 'Contexto',
    getContextClaim: () =>
      '¿Qué más hay que saber para entender la noticia? Te aportamos información adicional para entenderla mejor.',
    getContinueToRegistrationClaim: () =>
      'Para disfrutar de este servicio gratuito, sólo es necesario el registro en Payper:',
    getNewsSummaryTitle: () => 'Resumen del día',
    getShowMoreByCategoryLabel: (category) =>
      Boolean(category) ? `Más de ${category}` : 'Ver más artículos',
    getShowMoreLabel: () => 'Ver más',
    getShowLessLabel: () => 'Ver menos',
    getArticlesBriefTitle: () => 'Noticias recomendadas',
    getArticlesBriefPublicTitle: () => 'Artículos recomendados',
    getArticlesBriefPublicSubtitle: () =>
      'Descubre las noticias que hemos seleccionado para ti',
    getReadMoreLabel: () => 'Leer más...',
    getReadLabel: () => 'Ir al artículo',

    getInterestsTitle: () => 'Mis temas de interés',
    getSectionsTitle: () => 'Temas',
    getLibraryTitle: () => 'Librería',

    getPurchasesLabel: () => 'Compras',
    getPurchasesTitle: () => 'Tus compras',
    getPurchasesRowsPerPageLabel: () => 'Filas por página',
    getPurchasesDisplayedRowsLabel: (from, to, count) =>
      `${from} • ${to} de ${count}`,
    getPurchasesTableLabel: () => 'Artículos comprados',
    getPurchasesPrevPageLabel: () => 'Ir a la página anterior',
    getPurchasesNextPageLabel: () => 'Ir a la página siguiente',
    getPurchasesEmptyTitleLabel: () =>
      '¡Hay un universo de contenidos esperándote!',
    getPurchasesEmptyTextLabel: () => 'Aún no has realizado ninguna compra',

    getBonusLabel: () => 'Tienes un bono',
    getBonusLabelLongDesc: () => 'Tienes un bono activo',
    getBonusCardLabel: (partnerName) =>
      Boolean(partnerName)
        ? `Por cortesía de <b>${partnerName}</b>, tienes acceso libre a cualquier noticia en los medios asociados con <b>Payper</b> 🚀`
        : 'Tienes acceso libre a cualquier noticia en los medios asociados con <b>Payper</b> 🚀',
    getExpirationLabel: (date, hour) => `Caduca el ${date} a las ${hour}`,

    getBalanceCurrentLabel: () => 'Saldo actual',
    getBalanceTopUpLabel,
    getBalanceTopUpQuantityLabel: () => '¿Cuánto quieres recargar?',
    getBalanceTopUpAction: getBalanceTopUpLabel,
    getBalanceTopUpActionLong: () => 'Quiero recargar el saldo',
    getBalanceTopUpAmountAction: (amount) =>
      `Confirmar el pago de <b>${amount}€</b>`,
    getBalanceTopUpErrorLabel: () =>
      'Se ha producido un error durante la recarga, inténtalo de nuevo más tarde',
    getBalanceTopUpPopularLabel,
    getBalanceTopUpAlertTitleLabel,
    getBalanceTopUpAlertTextLabel,

    getBankCardAddLabel: () => 'Añadir tarjeta',
    getBankCardEditLabel: () => 'Cambiar tarjeta',
    getBankCardYourLabel: () => 'Esta es tu tarjeta asociada a Payper',
    getBankCardTitleLabel: getRegisterTitleLabel,
    getBankCardSubtitleLabel: () =>
      'Añade la tarjeta que quieres utilizar para desbloquear artículos.',
    getBankCardTextLabel: () =>
      'No pasaremos ningún cargo a la tarjeta. Recuerda que en Payper solo pagas cuando desbloqueas un artículo.',
    getBankCardBenefitsItemOneLabel: () =>
      'Desbloquea artículos a partir de <span>0,05 €</span>',
    getBankCardBenefitsItemTwoLabel: () =>
      '<span>Sin suscripciones</span> ni permanencias.',
    getBankCardBenefitsItemThreeLabel: () =>
      'Sólo <span>pagas por lo que lees</span>.',
    getBankCardSubmitAction: () => 'Activar tarjeta',
    getBankCardUpdatedLabel: () => 'Activa',
    getBankCardOutdatedLabel: () => 'Caducada',
    getBankCardNotUpdatedLabel: () => 'Sin tarjeta',
    getBankCardNotRegisteredLabel: () => 'Sin registrar',
    getBankCardEditAction: () => 'Cambiar tarjeta',
    getBankCardIntroAction: () => 'Introducir tarjeta',
    getBankCardOutdatedMessageLabel: () => 'Esta tarjeta no es válida',

    currentPasswordTitle: () => 'Escribe tu contraseña actual',
    newPasswordTitle: () => 'Introduce tu nueva contraseña',
    getUpdatePersonalDataCurrentPasswordLabel: () => 'Contraseña actual',
    getUpdatePersonalDataNewPasswordLabel: () => 'Nueva contraseña',
    getUpdatePersonalDataSuccessTitleLabel: () =>
      'Has cambiado tus datos de acceso',
    updatePersonalDataSuccess: () =>
      'Datos guardados. Usa estos datos a partir de ahora para acceder a Payper.',
    getUpdatePersonalDataSuccessContinueAction: () => 'De acuerdo',
    getUpdatePersDataInvalidOldPasswordError: () =>
      'Contraseña actual incorrecta',

    getUpdateBankCardTitleLabel: () =>
      'Introduce los datos de tu nueva tarjeta',
    getUpdateBankCardSuccessTitleLabel: () => '¡Has actualizado la tarjeta!',
    getUpdateBankCardSuccessMessageLabel: () =>
      '¡Bien hecho! Ya puedes seguir leyendo tus contenidos favoritos con Payper',
    getIntroBankCardCurrentCard: () => 'Tu tarjeta actual',
    getIntroBankCardTitleLabel: () => 'Introduce los datos de tu tarjeta',
    getIntroBankCardSuccessTitleLabel: () => '¡Has guardado la tarjeta!',
    getIntroBankCardSuccessMessageLabel: () =>
      '¡Bien hecho! Ya puedes comenzar a leer tus contenidos favoritos con Payper',

    getLoginFormNameLabel: () => 'Correo electrónico',
    getLoginFormPasswordLabel: () => 'Contraseña',
    getLoginFormTitleLabel: () => 'Sólo necesitamos estos datos',
    getLoginFormCtaAction: () => 'Iniciar sesión',
    getTogglePasswordAction: () => 'Mostrar u ocultar contraseña',
    getLoginFormSubmitAction: () => 'Entrar en mi cuenta',
    getPartnerLoginFormSubmitAction: () =>
      'Entrar en mi cuenta y<i>canjear mi bono</i>',
    getLoginFormForgotPasswordAction: () => 'No recuerdo la contraseña',
    getLoginInvalidCredentialsError: () => 'Usuario o contraseña inválidos',
    getLoginInvalidUserError: () =>
      'Su usuario no ha completado correctamente el registro, contacte con Payper para poder solucionarlo.',

    getParamsValidationErrorLabel: () => `Parámetros de la url incorrectos:`,
    getParamsValidationErrorLabelByParam: (param) =>
      `Parámetros de la url incorrectos con el parámetro "${param}":`,
    getParamsValidationErrorWillDeleteUrl: () =>
      '\nLos parámetros proporcionados en la url serán borrados.',

    getLogoutTitleLabel: () => '¿Quieres salir de Payper?',

    getIntroBankCardPriceLabel: () => 'Esta noticia tiene un coste de ',
    getIntroBankCardDataCaption: () =>
      'Registraremos tu tarjeta para que en la próxima compra no tengas que volver a introducirla.',
    getIntroBankCardSafePaymentLabel: () => 'Pago 100% seguro',
    getIntroBankCardCTA: (amount) => `Pagar ${amount}€ por esta noticia`,

    getApplePayTopUpTitle: () => 'Recarga tu saldo y lee con Payper',
    getGoToIntroBankCardAction: () => 'Pagar con tarjeta',
    getApplePayTopUpError: () =>
      'Se ha producido un error durante el proceso de recarga con ApplePay',

    getBalanceTopUpTitle: () => '¿Cuánto quieres recargar?',
    getBalanceTopUpAfterBankCardTitle: () =>
      '¿Sabes que puedes hacerte la vida más facil?',
    getBalanceTopUpDescription: () =>
      'Con una pequeña recarga podrás acceder a muchas más noticias sin tener que introducir tu tarjeta más veces.',

    getTopUpConfirmAction: (highlight) =>
      `Cargar mi tarjeta con <b>${highlight}€</b>`,
    getTopUpSkipAction: () => 'Quizá más adelante',

    getSuccessRegisterTitle: () => '¡Ya eres de Payper!',
    getSuccessRegisterLoadingSubtitle: () =>
      'Estamos preparando todo para que puedas leer noticias a la carta...',
    getSuccessRegisterSubtitle: () =>
      'Ya puedes leer tus noticias preferidas sin suscribirte. Desde 0,05€/noticia. Mira tu correo y descubre más.',
    getSuccessRegisterMediaText1: () =>
      'Elige la libertad de estar bien informado.',
    getSuccessRegisterMediaText2: () =>
      'Encuéntranos en todos estos medios. Cada vez vamos sumando más.',
    getRegisterCtaLabel: () => 'Acceder a la noticia',
    getSuccessPurchaseTitle: () => '¡Felicidades!',
    getSuccessPurchaseSubtitle: () =>
      'Ya tienes el contenido a tu disposición.',
    getPurchaseErrorTitle: () => 'Ha habido un error',
    getPurchaseErrorTitle2: () => 'Ups.. algo fue mal',
    getPurchaseErrorSubtitle: () =>
      'No se ha podido realizar la compra de la noticia. Te recomendamos que lo intentes de nuevo.',
    getTopUpErrorSubtitle: () =>
      'No se ha podido realizar la recarga solicitada. Te recomendamos que lo intentes de nuevo.',
    getPurchaseTopUpErrorTitle: () => 'Se ha producido un error',
    getPurchaseTopUpErrorSubtitle: () =>
      'No se ha podido realizar la recarga. Te recomendamos que lo intentes de nuevo.',
    getPurchaseErrorRetryAction: () => 'Volver a intentarlo',
    getPurchaseErrorCancelProcessAction: () => 'Volver al periódico',

    getGuarantorsLabel: () => 'Plataforma respaldada por',
    getContactFormTitleLabel: () => '¿Tienes dudas o comentarios?',
    getContactFormDescriptionLabel: () =>
      'Al enviar el contenido de este formulario, aceptas la política de privacidad y términos del servicio.',
    getContactFormNameLabel: () => 'Nombre',
    getContactFormSurnameLabel: () => 'Apellidos',
    getContactFormEmailLabel: () => 'Email',
    getContactFormMessageLabel: () => 'Déjanos aquí tu mensaje',
    getContactFormSuccessTitleLabel: () => '¡Mensaje recibido!',
    getContactFormSuccessDescriptionLabel: () =>
      'Nos pondremos en contacto contigo a la mayor brevedad posible.',
    getContactFormTitleError: () => 'Lo sentimos, algo ha salido mal...',
    getContactFormDescriptionError: () =>
      'Por razones técnicas ajenas a nuestra voluntad, no hemos podido enviar tu mensaje.',
    getContactFormSendAction: () => 'Enviar mi mensaje',
    getContactFormSendSuccessAction: () => '¿Quieres contarnos algo más?',

    getHeroPartnerTitleLabel: () =>
      'Lee la mejor prensa sin suscripciones gracias a ',
    getHomepageHeroTitleLabel: () => 'La libertad de estar bien informado.',
    getHomepageHeroItemOneLabel: () => 'Una cuenta para todos tus periódicos.',
    getHomepageHeroItemTwoLabel: () =>
      'Pága solo por las noticias que tú decidas leer.',
    getHomepageHeroItemThreeLabel: () =>
      'Desbloquea contenido premium a partir de 0,05 €.',
    getHomepageBenefitsItemOneLabel: () =>
      'Regístrate, sin permanencias ni cuotas.',
    getHomepageBenefitsItemTwoLabel: () =>
      'Rellena tu monedero con el importe que quieras.',
    getHomepageBenefitsItemThreeLabel: () =>
      'Lee contenido premium de nuestra red de periódicos.',
    getHomepageBenefitsTitleLabel: () => 'Cómo funciona Payper',
    getHomepageRegisterAction: () => 'Apuntarme gratis, en 2 minutos',
    getHomepageHightlightsItemOneLabel: () =>
      'Navega en tus periódicos<br />habituales',
    getHomepageHightlightsItemTwoLabel: () =>
      'A partir de 5 céntimos<br />por noticia',
    getHomepageHightlightsItemThreeLabel: () =>
      'Eres libre: tú decides<br />dónde y cuándo',
    getPrivacyPolicyLabel: () => 'Política de privacidad',
    getServiceTermsLabel: () => 'Términos y condiciones',
    getLegalNoticeLabel: () => 'Aviso legal',
    getLegalAgreementLabel1: () => 'He leído y acepto los ',
    getLegalAgreementLabel2: () => ' de Payper.',
    getFormAgreementLabel: () =>
      'Al enviar el contenido de este formulario, aceptas ',
    getLegalAgreementPrivacyPolicyLabel: () => 'Política de Privacidad',
    getLegalAgreementTermsLabel: () => 'Términos y Condiciones',
    getAndLabel: () => ' y la ',
    getLegalAgreementCommunicationsLabel: () =>
      'Acepto recibir comunicaciones comerciales de Payper y los medios asociados.',

    getRegisterResendEmailAction: () => 'Enviar el correo de nuevo',
    getRegisterLoginAction: () => 'Ya tengo cuenta',
    getGoToRegisterFromLogin1: () => '¿Ya tienes cuenta? ',
    getGoToRegisterFromLogin2: () => 'Entra aquí',
    getRegisterContinueAction: () => 'Continuar',
    getRegisterContinueAction2: () => '¡Vamos allá!',
    getRegisterAcceptAllAndContinueAction: () => 'Aceptar y continuar',
    getRegisterAcceptLegalAction: () => 'Aceptar condiciones legales',
    getRegisterCtaAction: () => 'Regístrate',
    getLoginCtaAction: () => 'Iniciar sesión',
    getAccessCtaAction: () => 'Comenzar',
    getRegisterJoinAction: () => 'Únete a Payper',
    getRegisterAction: () => 'Crear una cuenta en Payper',
    getForgottenPasswordAction: () => 'He olvidado mi contraseña',
    getCreatePasswordAction: () => 'Crear nueva contraseña',
    getCodeMismatchError: () =>
      'El código introducido no es correcto. Revísalo o pídenos uno nuevo.',
    getExpiredCodeError: () =>
      'El código introducido ha caducado. Pídenos uno nuevo.',
    getRegisterUserAlreadyExistsError: () =>
      'Ya existe una cuenta registrada con este correo',
    getRegisterTitleLabel: getRegisterTitleLabel,
    getRegisterSubtitleLabel: () => 'Crea tu cuenta Payper en 2 minutos',
    getRegisterCodeVerificationLabel: getRegisterTitleLabel,
    getRegisterCodeVerificationSubtitleLabel: () => 'Revisa tu email',
    getRegisterCodeVerificationTextLabel: () =>
      'Por seguridad, te hemos enviado un mensaje con un código de seguridad para confirmar el registro y que puedas acceder a tu cuenta.',
    getRegisterCodeVerificationNoteLabel: () =>
      'Si no ves el correo en tu bandeja de entrada, revisa la carpeta de SPAM.',
    getRegisterCodeVerificationRequired: () =>
      'Debes introducir todos los dígitos.',
    getRegisterCodeVerificationResendSuccedLabel: () =>
      'Se ha enviado un nuevo código, revise su bandeja de entrada',
    getRegisterConditionsLabel: () => 'condiciones',
    getRegisterTermsOfServiceLabel: () => 'términos del servicio',
    getRegisterPasswordRequirementsLabel: () =>
      'Tu contraseña debe tener 8 caracteres y al menos una mayúscula, una minúscula y un número',
    getRegisterBankCardError: () =>
      'Se ha producido un error al registrar la tarjeta',

    getRegisterTopUpTitleLabel: getRegisterTitleLabel,
    getRegisterTopUpSubtitleLabel: () =>
      'Ya casi está, ¿quieres hacer tu primera recarga?',
    getRegisterTopUpAction: (highlight) =>
      `Cargar mi tarjeta con <b>${highlight}</b>`,
    getRegisterTopUpSkipAction: () => 'Quizá en otro momento',
    getRegisterMinimumTopUpAction: (amount) =>
      `Quiero pagar solo <b>${amount}</b> por esta noticia`,

    getRegisterSuccessCaptionLabel: () => 'Enhorabuena 🎉',
    getRegisterSuccessTitleLabel: () => '¡Ya eres de Payper!',
    getRegisterSuccessDescriptionLabel: () =>
      'Ya puedes desbloquear el contenido premium de los periódicos que quieres leer.',
    getRegisterSuccessContinueAction: getGenericContinueAction,
    getRegisterSSOSuccessContinueAction: () => 'Continuar leyendo',

    getForgottenPasswordCaptionLabel: () => 'Nueva contraseña',
    getForgottenPasswordEnterEmailTitleLabel: () => 'Crea tu contraseña',
    getForgottenPasswordEnterEmailDescriptionLabel: () =>
      'Introduce tu email y te enviaremos un código para poder crear la contraseña.',
    getForgottenPasswordResetCodeLabel: () => 'Código de recuperación',
    getForgottenPasswordSendCodeAction: () => 'Enviar código',
    getForgottenPasswordSaveNewPasswordAction: () => 'Restablecer contraseña',
    getForgottenPasswordResendCodeAction: () => 'Enviar un nuevo código',
    getUpdatePasswordWithCodeSecurityClaim: () =>
      'Por seguridad, te vamos a enviar un código a la siguiente dirección de email:',
    getUpdatePasswordWithCodeInfoClaim: () =>
      'Introduce el código de seguridad enviado a tu email y tu nueva contraseña:',
    getUpdatePasswordWithCodeAction: () => 'Crear contraseña',

    getForgottenPasswordEnterCodeTitleLabel: () =>
      'Mira tu correo y completa el formulario',
    getForgottenPasswordEnterCodeDescriptionLabel: () =>
      'Añade el código enviado a tu email y escribe una nueva contraseña.',
    getForgottenPasswordEnterCodeAction: () => 'Enviar código',

    getNotFoundErrorTitle: () => 'No esperábamos verte por aquí...',
    getNotFoundErrorSubtitle: () =>
      'Parece que no existe esta página. Vuelve a tu página principal para seguir disfrutando del contenido de Payper.',
    getNotFoundErrorCTA: () => 'Volver a la página principal',
    getSomethingWentWrontErrorTitle: () => 'Algo no fue como se esperaba...',
    getSomethingWentWrontErrorSubtitle: () =>
      `Se ha producido un error durante el registro. Contacte con Payper si el problema persiste.`,
    getUnexpectedErrorTitle: () => 'Ups...  se ha producido un error',
    getUnexpectedErrorSubtitle: () =>
      'Contacte con Payper si el problema persiste.',
    getNetworkErrorTitle: () => 'Ups...  hay un error de conexión',
    getNetworkErrorSubtitle: () =>
      'Recarga la página cuando vuelvas a tener conexión.',
    getUnauthorizedErrorTitle: () =>
      'Se ha producido un error durante su autenticación',
    getUnauthorizedErrorSubtitle: () =>
      'Contacte con nosotros si el problema persiste.',
    getUnexpectedErrorCTA: () => 'Volver a cargar la página',
    getUnexpectedErrorSecondaryButton: () => 'Ir a mi página principal',

    getCookiesTitle: () => 'Política de cookies',
    getCookiesAriaLabel: () => 'Modal de política de cookies',
    getCookiesContent: () =>
      'Utilizamos cookies propias y de terceros para fines analíticos y para poder ofrecerte un mejor servicio. Puedes saber más de nuestra ',
    getCookiesContent2: () => 'política de cookies.',
    getAcceptCTA: () => 'Aceptar',
    getRefuseCTA: () => 'Rechazar',

    getUseGoogleAccountCTA: () => 'Usar mi cuenta Google',
    getUseAppleAccountCTA: () => 'Usar mi cuenta Apple',

    getPayperMarketingLabel: () => 'Servicio ofrecido por ',
    getLoginWidgetHeadingLabel: () =>
      '¡Bienvenido a Payper!<br />La libertad de estar bien informado',
    getLoginWidgetItemOneLabel: () => 'Sin suscripciones.',
    getLoginWidgetItemTwoLabel: () => 'Con Payper, paga sólo por lo que lees.',
    getLoginWidgetItemThreeLabel: () => 'En los medios que te gustan.',
    getLoginWidgetRegisterLabel: () => '¿Aún no tienes una cuenta?',
    getLoginWidgetRegisterTextLabel: () =>
      'Crea tu cuenta en 2 minutos y empieza a disfrutar de tus contenidos favoritos.',
    getLoginWidgetRegisterCtaLabel: () => 'Crea tu cuenta en<i>dos minutos</i>',
    getLoginPartnerRegisterCtaLabel: () =>
      'Crear mi cuenta y<i>canjear mi bono</i>',
    getLoginWidgetSocialLabel: () => 'O identifícate con...',
    getLoginWidgetLoginLabel: () => 'Entra en tu cuenta Payper',

    getPrivacySummaryQuestion1: () => '¿Qué empresa trata tus datos?',
    getPrivacySummaryQuestion2: () =>
      '¿Por qué tratamos los datos que te pedimos?',
    getPrivacySummaryQuestion3: () =>
      '¿Cuál es la legitimación para este tratamiento de tus datos?',
    getPrivacySummaryQuestion4: () =>
      '¿Se van a hacer cesiones o transferencias con tus datos?',
    getPrivacySummaryQuestion5: () => '¿Cuáles son mis derechos?',
    getPrivacySummaryQuestion6: () =>
      '¿Se llevará a cabo un perfilado de los datos?',
    getPrivacySummaryQuestion7: () => '¿Tienes dudas?',

    getPrivacySummaryResponse1: () =>
      'MARTER 878, S.L. (en adelante, “PAYPER”)',
    getPrivacySummaryResponse2: () =>
      'Tratamos tus datos para poder prestarte los servicios de PAYPER y enviarte información sobre nuestros productos y/o servicios, así como para ceder tus datos a empresas del sector de medios de comunicación para que puedan prestarte servicios propios e impactarte con comunicaciones comerciales sobre sus servicios y/o productos.',
    getPrivacySummaryResponse3: () =>
      'Estos datos son necesarios para llevar a cabo la prestación de los servicios que se hayan solicitado a través de la Plataforma, así como el consentimiento que nos hayas otorgado conforme a nuestra Política de Privacidad.',
    getPrivacySummaryResponse4: () =>
      'Tus datos podrán cederse a medios de comunicación para la prestación de servicios propios y el envío de comunicaciones comerciales de los mismos.',
    getPrivacySummaryResponse5: () =>
      'El interesado tiene derecho a ejercitar su derecho de:',
    getPrivacySummaryResponseList5: () => [
      'Acceso.',
      'Rectificación.',
      'Supresión.',
      'Oposición.',
      'Portabilidad de los Datos.',
      'Limitación del Tratamiento.',
      'No ser objeto de decisiones automatizadas individualizadas.',
    ],
    getPrivacySummaryResponse6: () =>
      'Sí, se llevará a cabo un perfilado de tus datos personales por parte de PAYPER. Sin embargo, tienes derecho a oponerte a que tus datos personales sean objeto de un tratamiento basado en la elaboración de perfiles en ciertas circunstancias.',
    getPrivacySummaryResponse7: () =>
      'Tanto si tienes alguna duda o sugerencia como si quieres darte de baja ponte en contacto con nosotros enviando un email a la siguiente dirección: info@bepayper.com',
    getPrivacySummaryLinkLabel7: () => 'Contacta con Payper',

    onboardingStep1Title: () => '¡Bienvenido a Payper!',
    onboardingStep1Text: () =>
      'Accede a los artículos premium de tus diarios favoritos sin necesidad de suscripciones.',
    onboardingStep2Title: () => '¿Cómo funciona?',
    onboardingStep2Text: () =>
      'Regístrate y crea tu cuenta gratis. \nPayper aparecerá automáticamente mientras navegas por tu periódico para desbloquear el contenido de pago.',
    onboardingStep2Caption: () =>
      'Te invitamos a una noticia premium, ¡cada día!',
    onboardingStep3Title: () => 'Sólo te queda un paso',
    onboardingStep3Text: () =>
      'Fija Payper a tu navegador y podrás acceder rapidamente a todas sus funcionalidades mientras estás leyendo tus artículos en el diario',
    onboardingStep4Title: () => 'Tu pase directo a las noticias premium',
    onboardingStep4Description: () =>
      `¿Te imaginas poder leer los mejores artículos de los principales periódicos de España? Con Payper puedes, pagando solo 5 céntimos por artículo.`,
    onboardingStep4Bullet1Icon: () => '🎁',
    onboardingStep4Bullet1Text: () =>
      '<b>Regalo de Bienvenida:</b> Por ser usuario de Payper, te invitamos a una noticia premium cada día, ¡para siempre! 🥳',
    onboardingStep4Bullet2Icon: () => '🪙',
    onboardingStep4Bullet2Text: () =>
      '<b>Pago por artículo:</b> Solo paga por los artículos que realmente te interesan.',
    onboardingStep4Bullet3Icon: () => '✨',
    onboardingStep4Bullet3Text: () =>
      '<b>Fácil de usar:</b> Payper se integra de manera rápida y sencilla en tu navegador, apareciendo solo cuando te encuentras con contenido de pago.',
    onboardingStep4Comment1Text: () =>
      '“Por fin alguien lo hace: poder acceder a un solo contenido por un precio razonable sin tener que pagar una suscripción a un medio que, en general, no te interesa.”',
    onboardingStep4Comment1Author: () => 'Denís',
    onboardingStep4Comment2Text: () =>
      '“Una increíble idea para leer artículos de forma puntual y sin necesidad de una suscripción."',
    onboardingStep4Comment2Author: () => 'Alfonso',
    next: () => 'Siguiente',

    //
    discovery: () => 'Explorar',
    library: () => 'Mi librería',
    read: () => 'Leídos',
    favorite: () => 'Favoritos',
    account: () => 'Cuenta',
    myAccount: () => 'Mi cuenta',
    homeWizardTitle: () => 'Tu acceso directo a Payper',
    homeWizardStep0: () => 'Hazlo en 2 pasos',
    homeWizardStep1Ios: () => '1. Click en Botón de compartir',
    homeWizardStep1Android: () => '1. Click sobre el botón del menú',
    homeWizardStep2: () => "2. Click en 'Añadir a inicio'",
    homeWizardStep0ImgAlt: () => 'Captura de acceso directo a Payper',
    homeWizardStep1ImgAlt: () =>
      'Captura de botón de compartir en la barra de navegación móvil',
    homeWizardStep2ImgAlt: () =>
      "Captura de botón 'Añadir a pantalla de inicio' del submenú del navegador móvil",
    homeWizardAction: () => 'Ok, entendido',
    clearAriaLabel: () => 'limpiar campo de búsqueda',
    backAriaLabel: () => 'volver atrás',
    searchPlaceholder: () => '¿Sobre qué quieres leer?',
    searchAction: () => 'Buscar',
    readDate: () => 'Leído',
    publishedDate: () => 'Publicado',
    free: () => 'Gratis',
    payperSlogan: () => 'Prensa premium sin restricciones',
    balanceTopUp: () => 'Saldo de recargas',
    promotion: () => 'Promoción',
    balance: () => 'Saldo',
    bankCard: () => 'Tarjeta',
    personalData: () => 'Datos personales',
    updatePersonalData: () => 'Cambiar datos personales',
    updatePersonalDataTitle: () => 'Mis datos',
    saveChanges: () => 'Guardar los cambios',
    updatePassword: () => 'Cambiar contraseña',
    createNewPassword: () => 'Crear nueva contraseña',
    communicationsAgreementAcepted: () =>
      'Acepto recibir comunicaciones comerciales de Payper y medios asociados',
    communicationsAgreementNotAcepted: () =>
      'No acepto recibir comunicaciones comerciales de Payper y medios asociados',
    incompleteData: () => 'Tus datos están incompletos',
    balancePayperPolicy: () =>
      'Los artículos que en los periódicos son exclusivos para suscriptores, en Payper los puedes leer por solo <b>5 céntimos.</b>',
    noBalance: () => 'Todavía no has añadido saldo',
    lowBalance: () =>
      'No tienes mucho saldo, recarga para seguir disfrutando de <b>Payper</b>',
    invalidBankCardWarning: () => 'Parece que tu tarjeta está caducada',
    emptyBankCardWarning: () =>
      'Añade tu tarjeta de crédito para desbloquear nuevo contenido.',
    newspapers: () => 'Periódicos',
    magazines: () => 'Revistas',
    showAllMedias: () => 'Ver todos los periódicos',
    endOfServiceText1Default: () =>
      '<b>A partir de ahora, Payper es un servicio exclusivo para empresas</b> que deseen ofrecer prensa a sus clientes o empleados.',
    endOfServiceText2Default: () =>
      'Si deseas más información sobre cómo seguir usando Payper a través de tu empresa, accede aquí:',
    endOfServiceActionLableDefault: () => 'Saber más',
    endOfServiceText1Partner: (partnerName) =>
      `Tu sesión de lectura por cortesía de <b>${partnerName}</b> ha terminado. Gracias por leer con Payper.`,
    seeAll: () => 'Ver todos',
    seeAllNewspapers: () => 'Ver todos los periódicos',
    seeLess: () => 'Ver menos',
  }
}

export const useTexts = () => getTexts()
