import { useTexts } from '../../../../texts'
import { PageBlock } from '../../../Layout'
import useMediaButtons from '../../useMediaButtons'
import { AssociatedMedia } from '../../../components'

const Magazines = () => {
  const texts = useTexts()

  const { magazines, onClickMedia } = useMediaButtons()

  return (
    <PageBlock title={texts.magazines()}>
      <AssociatedMedia media={magazines} onClickMedia={onClickMedia} />
    </PageBlock>
  )
}

export default Magazines
