import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts.js'
import {
  Alert,
  FormSkinWrapper,
  TextField,
  HelperText,
} from '../../../components/index.js'
import { Icons } from '../../../../assets/index.js'

const styles = {
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 'var(--spacing-l)',
  },
}

const View = ({
  formId,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  isSubmitSuccess,
}) => {
  const texts = useTexts()
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  return (
    <Box className='secondary-page-content'>
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.wrapFields}>
          <Box>
            <Typography variant='subtitle1'>
              {texts.currentPasswordTitle()}
            </Typography>
            <TextField
              id='oldPassword'
              name='oldPassword'
              autoComplete='current-password'
              label={texts.getUpdatePersonalDataCurrentPasswordLabel()}
              type={showOldPassword ? 'text' : 'password'}
              helperText={fieldErrors.oldPassword}
              error={Boolean(fieldErrors.oldPassword)}
              InputProps={{
                readOnly: isSubmitSuccess() ? true : false,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icons.LockClosed />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() =>
                      setShowOldPassword((showOldPassword) => !showOldPassword)
                    }
                  >
                    <IconButton aria-label={texts.getTogglePasswordAction()}>
                      {showOldPassword ? (
                        <Icons.EyeFilled />
                      ) : (
                        <Icons.EyeClosed />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          </Box>
          <Box>
            <Typography variant='subtitle1'>
              {texts.newPasswordTitle()}
            </Typography>

            <TextField
              id='newPassword'
              name='newPassword'
              label={texts.getUpdatePersonalDataNewPasswordLabel()}
              type={showNewPassword ? 'text' : 'password'}
              helperText={fieldErrors.newPassword}
              error={Boolean(fieldErrors.newPassword)}
              InputProps={{
                readOnly: isSubmitSuccess() ? true : false,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icons.LockClosed />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() =>
                      setShowNewPassword((showPassword) => !showPassword)
                    }
                  >
                    <IconButton aria-label={texts.getTogglePasswordAction()}>
                      {showNewPassword ? (
                        <Icons.EyeFilled />
                      ) : (
                        <Icons.EyeClosed />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          </Box>
          <Box mt={-0.5}>
            <HelperText text={texts.getRegisterPasswordRequirementsLabel()} />
          </Box>
          {Boolean(formError) ? (
            <Alert variant='error' text={formError} />
          ) : null}
          {isSubmitSuccess() ? (
            <Alert variant='success' text={texts.updatePersonalDataSuccess()} />
          ) : null}
          <LoadingButton
            fullWidth
            color='primary'
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting || isSubmitSuccess()}
            loadingIndicator={<CircularProgress size={16} />}
          >
            {texts.saveChanges()}
          </LoadingButton>
          {isSubmitSuccess() && (
            <Button fullWidth component={Link} to='/account' color='secondary'>
              {texts.goBack()}
            </Button>
          )}
        </FormSkinWrapper>
      </form>
    </Box>
  )
}

export default View
