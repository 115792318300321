import { Box } from '@mui/material'

import EnterCodeAndNewPasswordStep from './EnterCodeAndNewPasswordStep'
import ConfirmSendCodeStep from './ConfirmSendCodeStep'
import { STEPS } from './index'

const UpdatePasswordWithCodeView = ({
  stepState,
  email,
  sendCodeProps,
  enterNewPasswordProps,
}) => {
  return (
    <Box className='secondary-page-content'>
      {stepState.name === STEPS.SEND_CODE ? (
        <ConfirmSendCodeStep
          email={email}
          error={stepState.error}
          {...sendCodeProps}
        />
      ) : (
        <EnterCodeAndNewPasswordStep email={email} {...enterNewPasswordProps} />
      )}
    </Box>
  )
}

export default UpdatePasswordWithCodeView
