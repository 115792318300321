import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MobileStepper } from '@mui/material'

import { useTexts } from '../../../texts'
import * as a9 from '../../../analytics'
import avatar1 from '../../../assets/images/avatar_woman.jpeg'
import avatar2 from '../../../assets/images/avatar_man_1.jpeg'
import avatar3 from '../../../assets/images/avatar_man_2.jpeg'
import { Picture1, Picture2, Picture3 } from './pictures'
import View from './View'
import { DocumentCookies } from '../../utils'

const ONBOARDING_COOKIE_NAME = 'payper_onboarding_bonus'
const PARAM_ACTIONS_DEFAULT_VALUE =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiY3JlYXRlX2JvbnVzIiwicGF5bG9hZCI6eyJ0eXBlIjoiYmFsYW5jZSIsInBhcnRuZXIiOiI0MmY3M2NjZi1jZWMwLTQyOGItYWU5OC0zNmQwNjQ5YzA4ZmEiLCJjb2RlIjoib25ib2FyZGluZ19leHRlbnNpb24iLCJhbW91bnQiOiIwLjA1IiwiZHVyYXRpb24iOiIxRCIsIm1heF9vY3VycmVuY2VzIjoiMSJ9fQ.Q2MyXB0UgmVCqLt8kKwFAgXGnuVZEFfrX5iNPaAl9S4'
const CHROME_ONBOARDING_ACCESS_SEARCH_PARAMS = {
  utm_source: 'payper',
  utm_medium: 'web',
  utm_campaign: 'onboarding_chrome_ext',
  actions:
    new DocumentCookies().get(ONBOARDING_COOKIE_NAME) ??
    PARAM_ACTIONS_DEFAULT_VALUE,
}
const CHROME_ONBOARDING_ACCESS_PATHNAME = '/onboarding-access'

const getAccessPath = () => {
  const currentSearchParamsObj = {}
  for (const [key, value] of new URLSearchParams(
    window.location.search,
  ).entries()) {
    currentSearchParamsObj[key] = value
  }

  const accessSearchParams = new URLSearchParams({
    ...CHROME_ONBOARDING_ACCESS_SEARCH_PARAMS,
    ...currentSearchParamsObj,
  }).toString()

  return `${CHROME_ONBOARDING_ACCESS_PATHNAME}?${accessSearchParams}`
}

const OnboardingChromeExtension = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)

  const handleClickNextStep = () => {
    a9.dispatchEvent(STEPS[activeStep].eventName)

    if (activeStep === 2) {
      navigate(getAccessPath())
    } else {
      setActiveStep((s) => s + 1)
    }
  }

  const STEPS = [
    {
      title: texts.onboardingStep1Title(),
      text: texts.onboardingStep1Text(),
      avatar: {
        image: avatar1,
        caption: undefined,
      },
      image: <Picture1 />,
      eventName: a9.EVENTS.PO_CLICK_STEP1_WELCOME,
    },
    {
      title: texts.onboardingStep2Title(),
      text: texts.onboardingStep2Text(),
      avatar: {
        image: avatar2,
        caption: texts.onboardingStep2Caption(),
      },
      image: <Picture2 />,
      eventName: a9.EVENTS.PO_CLICK_STEP2_CE_WORK,
    },
    {
      title: texts.onboardingStep3Title(),
      text: texts.onboardingStep3Text(),
      avatar: {
        image: avatar3,
        caption: undefined,
      },
      image: <Picture3 />,
      eventName: a9.EVENTS.PO_CLICK_STEP3_CE_PIN,
    },
  ]

  return (
    <View
      {...STEPS[activeStep]}
      onClickNextStep={handleClickNextStep}
      renderStep={() => (
        <MobileStepper
          steps={STEPS.length}
          position='static'
          activeStep={activeStep}
        />
      )}
    />
  )
}

export default OnboardingChromeExtension
