import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import { isIOS, browser } from '../../utils'
import * as PT from '../../propTypes'
import styles from './mobileBottomNavigation.styles'

const MOBILE_BOTTOM_NAVIGATION_ID = 'mobile-bottom-navigation'

const getInitialActionIndex = (actions) => {
  const actionIndexFromUrl = actions.findIndex((action) =>
    window.location.pathname.includes(action.path),
  )
  return Boolean(actionIndexFromUrl) ? actionIndexFromUrl : 0
}

const MobileBottomNavigationPropTypes = {
  actions: PT.NavigationActions.isRequired,
}

const MobileBottomNavigation = ({ actions }) => {
  const navigate = useNavigate()

  const [navIndex, setNavIndex] = useState(getInitialActionIndex(actions))

  return (
    <BottomNavigation
      id={MOBILE_BOTTOM_NAVIGATION_ID}
      showLabels
      value={navIndex}
      onChange={(_, newNavIndex) => {
        setNavIndex(newNavIndex)
        navigate(actions[newNavIndex].path)
      }}
      sx={{
        ...styles.root,
        ...(isIOS() && browser.isChrome() ? styles.xtraMarginBottom : {}),
      }}
    >
      {actions.map((action, i) => (
        <BottomNavigationAction
          key={i}
          label={action.label}
          icon={action.icon}
        />
      ))}
    </BottomNavigation>
  )
}
MobileBottomNavigation.propTypes = MobileBottomNavigationPropTypes

export default MobileBottomNavigation
