import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { Icons } from '../../../../../../assets'
import { User } from '../../../../../../business'
import * as a9 from '../../../../../../analytics'
import { useTexts } from '../../../../../../texts'
import { formatNumber } from '../../../../../format'
import {
  BankCardRegistration,
  CircularProgress,
} from '../../../../../components'

import styles from './bankCardStepWidget.styles'

const BankCardStepWidget = ({ sub, articlePrice, onContinue }) => {
  const texts = useTexts()
  const [isUpdatingBalance, setIsUpdatingBalance] = useState(false)

  const handleSuccessBankCardRegistration = async () => {
    setIsUpdatingBalance(true)
    await User.updateAccountBalance(articlePrice)

    a9.dispatchEvent(a9.EVENTS.PM_FORM_SUBMIT_REGISTER_BANKCARD, {
      status: 'success',
    })
    onContinue()
  }

  const handleDispatchEventOnError = (errorMessage) => {
    a9.dispatchEvent(a9.EVENTS.PM_FORM_SUBMIT_REGISTER_BANKCARD, {
      status: 'error',
      formError: errorMessage,
    })
  }

  return (
    <Box sx={styles.root}>
      {!Boolean(articlePrice) || isUpdatingBalance ? (
        <CircularProgress />
      ) : (
        <>
          <BankCardRegistration
            onSuccess={handleSuccessBankCardRegistration}
            onError={handleDispatchEventOnError}
            sub={sub}
            customCtaConfig={{
              icon: <Icons.Coins1 />,
              iconStyles: { left: '10%' },
              buttonStyles: 'padding-right: 18%',
              label: texts.getIntroBankCardCTA(formatNumber(articlePrice)),
            }}
            renderBankCardCustomContent={() => (
              <Typography variant='body1' sx={{ mb: 0.5 }}>
                {texts.getIntroBankCardDataCaption()}
              </Typography>
            )}
          />
        </>
      )}
    </Box>
  )
}

export default BankCardStepWidget
