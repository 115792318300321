import { useState } from 'react'
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
  Box,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link } from 'react-router-dom'

import { EVENTS } from '../../../../analytics'
import {
  FormSkinWrapper,
  Alert,
  TextField,
  HelperText,
} from '../../../components'
import { Icons } from '../../../../assets'
import { useTexts } from '../../../../texts'
import { useFormV2, composeValidators, validators } from '../../../../forms'
import {
  isValidPassword,
  // isValidCode
} from '../../../../business/validate'
import styles from '../../AccessPages/ForgottenPassword/forgottenPassword.styles'

const CHANGE_PASSWORD_CONFIRM_FORM_ID =
  EVENTS.PD_FORM_SUBMIT_CHANGE_PASSWORD_CONFIRM

const useEnterCodeAndNewPasswordForm = (email, submitUpdatePasswordFn) => {
  const texts = useTexts()

  const FORM_VALIDATION_CONFIG = {
    verificationCode: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      // [isValidCode, texts.getErrorCode()],
    ]),
    newPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
  }

  const submitForm = async (formValues) =>
    await submitUpdatePasswordFn(
      email,
      formValues.verificationCode,
      formValues.newPassword,
    )

  return useFormV2(
    CHANGE_PASSWORD_CONFIRM_FORM_ID,
    submitForm,
    FORM_VALIDATION_CONFIG,
  )
}

const EnterCodeAndNewPasswordStep = ({
  email,
  submitUpdatePasswordFn,
  onClickResendCode,
}) => {
  const texts = useTexts()

  const [showNewPassword, setShowNewPassword] = useState(false)

  const { status, isSubmitSuccess, submitForm } =
    useEnterCodeAndNewPasswordForm(email, submitUpdatePasswordFn)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  return (
    <form
      id={CHANGE_PASSWORD_CONFIRM_FORM_ID}
      onSubmit={handleSubmit}
      noValidate
    >
      <FormSkinWrapper skin='light' sx={styles.wrapFields}>
        <Typography variant='subtitle1'>
          {texts.getUpdatePasswordWithCodeInfoClaim()}
        </Typography>
        <TextField
          id='verificationCode'
          name='verificationCode'
          label={texts.getForgottenPasswordResetCodeLabel()}
          type={'text'}
          helperText={status.fieldErrors.verificationCode}
          error={Boolean(status.fieldErrors.verificationCode)}
          required
        />

        <TextField
          id='newPassword'
          name='newPassword'
          label={texts.getUpdatePersonalDataNewPasswordLabel()}
          type={showNewPassword ? 'text' : 'password'}
          helperText={status.fieldErrors.newPassword}
          error={Boolean(status.fieldErrors.newPassword)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icons.LockClosed />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                onClick={() =>
                  setShowNewPassword((showPassword) => !showPassword)
                }
              >
                <IconButton aria-label={texts.getTogglePasswordAction()}>
                  {showNewPassword ? <Icons.EyeFilled /> : <Icons.EyeClosed />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <Box mt={-0.5} mb={0.5}>
          <HelperText text={texts.getRegisterPasswordRequirementsLabel()} />
        </Box>

        {Boolean(status.formError) && (
          <Alert variant='error' text={status.formError} />
        )}
        {isSubmitSuccess() ? (
          <Alert variant='success' text={texts.updatePersonalDataSuccess()} />
        ) : null}

        <Box sx={styles.actions}>
          <LoadingButton
            type='submit'
            color='primary'
            loading={status.isSubmitting}
            disabled={status.isSubmitting || isSubmitSuccess()}
            loadingIndicator={<CircularProgress size={16} />}
            fullWidth
          >
            {texts.getUpdatePasswordWithCodeAction()}
          </LoadingButton>
          {isSubmitSuccess() ? (
            <Button
              fullWidth
              component={Link}
              to='/account'
              color='secondary'
              startIcon={<Icons.ArrowLeft />}
            >
              {texts.goBack()}
            </Button>
          ) : (
            <Button color='secondary' onClick={onClickResendCode} fullWidth>
              {texts.getForgottenPasswordResendCodeAction()}
            </Button>
          )}
        </Box>
      </FormSkinWrapper>
    </form>
  )
}
export default EnterCodeAndNewPasswordStep
