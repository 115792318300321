import packageJson from '../package.json'

export const appVersion = packageJson.version

export const backofficeEndpoint = process.env.REACT_APP_BACKOFFICE_ENDPOINT
export const eventsEndpoint = process.env.REACT_APP_PAYPER_EVENTS_ENDPOINT
export const actionsAppUrl = process.env.REACT_APP_ACTIONS_URL

export const getTestTimeout = () => Number(process.env.REACT_APP_JEST_TIMEOUT)
export const hasTestTimeout = () =>
  process.env.REACT_APP_JEST_TIMEOUT !== undefined

const hasCognitoUserPoolDomain = () =>
  process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN !== undefined
const getCognitoUserPoolDomain = () =>
  process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN

const hasAuthorizeRespType = () =>
  process.env.REACT_APP_AUTHORIZE_RESP_TYPE !== undefined
const getAuthorizeRespType = () => process.env.REACT_APP_AUTHORIZE_RESP_TYPE

const hasCognitoAppClientId = () =>
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID !== undefined
const getCognitoAppClientId = () => process.env.REACT_APP_COGNITO_APP_CLIENT_ID

const hasCognitoRedirectUri = () =>
  process.env.REACT_APP_COGNITO_REDIRECT_URI !== undefined
const getCognitoRedirectUri = () => process.env.REACT_APP_COGNITO_REDIRECT_URI

const hasCognitoSSORedirectUri = () =>
  process.env.REACT_APP_COGNITO_SSO_REDIRECT_URI !== undefined
const getCognitoSSORedirectUri = () =>
  process.env.REACT_APP_COGNITO_SSO_REDIRECT_URI

export const hasCognitoConfig = () => {
  if (
    !hasCognitoUserPoolDomain() ||
    !hasAuthorizeRespType() ||
    !hasCognitoAppClientId() ||
    !hasCognitoRedirectUri() ||
    !hasCognitoSSORedirectUri()
  ) {
    return false
  }

  return true
}

export const getCognitoConfig = () => ({
  poolDomain: getCognitoUserPoolDomain(),
  authorizeRespType: getAuthorizeRespType(),
  appClientId: getCognitoAppClientId(),
  redirectUri: getCognitoRedirectUri(),
  ssoRedirectUri: getCognitoSSORedirectUri(),
})
