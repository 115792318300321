import { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import * as analytics from '../../../../analytics'
import { User, Session, Auth } from '../../../../business'
import { CircularProgress } from '../../../components'

import View from './View'

const STEP_ACTIONS = {
  SET_STEP_ERROR: 'SET_STEP_ERROR',
  RESET_STEP_ERROR: 'RESET_STEP_ERROR',
  SET_STEP_NAME: 'SET_STEP_NAME',
}

export const STEPS = {
  SEND_CODE: 'SEND_CODE',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
}

const reducer = (state, action) => {
  if (action.type === STEP_ACTIONS.RESET_STEP_ERROR) {
    return {
      ...state,
      error: undefined,
    }
  } else if (action.type === STEP_ACTIONS.SET_STEP_ERROR) {
    return {
      ...state,
      error: action.payload.error,
    }
  } else if (action.type === STEP_ACTIONS.SET_STEP_NAME) {
    if (!Object.keys(STEPS).includes(action.payload.name)) {
      throw new Error('Invalid step name')
    }

    return {
      ...state,
      name: action.payload.name,
      error: undefined,
    }
  }
}

export const INITIAL_STEP_STATE = {
  name: STEPS.SEND_CODE,
  error: undefined,
}

const UpdatePasswordWithCode = ({ email }) => {
  const navigate = useNavigate()

  const [stepState, dispatchStepState] = useReducer(reducer, INITIAL_STEP_STATE)

  const handleSendCode = async () => {
    dispatchStepState({ type: STEP_ACTIONS.RESET_STEP_ERROR })
    try {
      await Auth.initForgottenPassword(email)
      analytics.dispatchEvent(
        analytics.EVENTS.PD_FORM_SUBMIT_CHANGE_PASSWORD_SEND_CODE,
        { status: 'success' },
      )
      dispatchStepState({
        type: STEP_ACTIONS.SET_STEP_NAME,
        payload: { name: STEPS.CONFIRM_PASSWORD },
      })
    } catch (e) {
      dispatchStepState({
        type: STEP_ACTIONS.SET_STEP_ERROR,
        payload: { error: e.message ?? 'error' },
      })
      analytics.dispatchEvent(
        analytics.EVENTS.PD_FORM_SUBMIT_CHANGE_PASSWORD_SEND_CODE,
        { status: 'error', form_error: e?.message },
      )
    }
  }

  const handleClickResendCode = async () => {
    dispatchStepState({
      type: STEP_ACTIONS.SET_STEP_NAME,
      payload: { name: STEPS.SEND_CODE },
    })
    analytics.dispatchEvent(
      analytics.EVENTS.PD_CLICK_CHANGE_PASSWORD_RESEND_CODE,
    )
  }

  useEffect(() => {
    if (Session.getIs3rdPartyIdpAccount()) {
      navigate('..', { relative: 'path' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View
      stepState={stepState}
      email={email}
      sendCodeProps={{ onClickSendCode: handleSendCode }}
      enterNewPasswordProps={{
        submitUpdatePasswordFn: Auth.confirmForgottenPassword,
        onClickResendCode: handleClickResendCode,
      }}
    />
  )
}

const withUserEmail = (Component) => {
  return (props) => {
    const {
      state: { data: userData },
    } = User.usePersonalData()

    if (!userData || !userData?.email) return <CircularProgress />
    return <Component {...props} email={userData.email} />
  }
}

export default withUserEmail(UpdatePasswordWithCode)
