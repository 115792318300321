import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as a9 from '../../../analytics'
import { Auth, Session, URL_SEARCH_PARAMS_AUTH_KEYS } from '../../../business'
import { useNavigateWithSearchParams } from '../../utils'
import { CircularProgress } from '../../components'
import useSearchParams from './useSearchParams'
import useAccessFinish from './useAccessFinish'

const getAuthCodeFromUrlParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE)
}

const dispatchAnalyticsSuccessEvent = (
  identityProvider,
  hasSystemUser,
  userSub,
) => {
  let name = ''
  if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE) {
    name = hasSystemUser
      ? a9.EVENTS.PA_FORM_SUBMIT_LOGIN_APPLE
      : a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_APPLE
  } else if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE) {
    name = hasSystemUser
      ? a9.EVENTS.PA_FORM_SUBMIT_LOGIN_GOOGLE
      : a9.EVENTS.PA_FORM_SUBMIT_SIGNUP_GOOGLE
  }

  a9.dispatchEvent(name, {
    userSub,
    status: 'success',
  })
}

const SuccessThirdPartyIdpAccess = () => {
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const {
    state: { identityProvider },
  } = useLocation()

  const paramsData = useSearchParams()
  const accessFinish = useAccessFinish(paramsData)

  useEffect(() => {
    ;(async () => {
      if (Boolean(paramsData)) {
        const code = getAuthCodeFromUrlParam()
        const tokens = await Auth.getJWTs(code)

        const { hasSystemUser } = await Auth.verifyCompleteRegistration(
          tokens.accessToken,
        )
        if (!hasSystemUser) {
          if (
            !Session.readStoredSearchParam(
              Session.STORED_URL_PARAMS.PARTNER_ID,
            ) ||
            !paramsData?.isReferred
          ) {
            await Auth.removeIdpAccountInCognito(tokens)
            navigateWithSearchParams('/access', {
              state: { referredRegistrationError: true },
            })
            return
          } else {
            await Auth.registerIdpAccountInTransactionalDB(
              tokens,
              paramsData?.attribution,
            )
          }
        }

        Session.storeSession(tokens)

        dispatchAnalyticsSuccessEvent(
          identityProvider,
          hasSystemUser,
          Session.getUserSub(),
        )

        accessFinish.handleFinishAuth({ isNewUser: !hasSystemUser })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData])

  return <CircularProgress height='80vh' />
}

export default SuccessThirdPartyIdpAccess
