import { useNavigate } from 'react-router-dom'

import { User, Session } from '../../../../business'
import * as a9 from '../../../../analytics'
import View from './View'

const AccountPage = () => {
  const navigate = useNavigate()

  const promotionState = User.usePromotion()
  const { state: bankCardState } = User.useBankCard()
  const { state: balanceState } = User.useBalance()
  const {
    state: { data: userData },
  } = User.usePersonalData()

  const onClickTopUp = () => {
    navigate('top-up-balance')
  }
  const onClickEditBankCard = () => {
    navigate('bank-card-edition')
  }
  const onClickAddBankCard = () => {
    navigate('bank-card-introduction')
  }
  const onClickUpdatePersonalData = () => {
    navigate('update-personal-data')
  }
  const onClickUpdatePassword = () => {
    navigate('update-password-with-code')
  }

  const handleLogout = () => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_LOGOUT)
    Session.logout()
  }

  return (
    <View
      bankCardData={bankCardState}
      balanceData={balanceState}
      userData={userData}
      bonusData={{ ...promotionState, data: promotionState.data?.bonus }}
      partnerName={promotionState?.data?.partner?.name}
      onClickTopUp={onClickTopUp}
      onClickEditBankCard={onClickEditBankCard}
      onClickAddBankCard={onClickAddBankCard}
      onClickUpdatePersonalData={onClickUpdatePersonalData}
      onClickUpdatePassword={onClickUpdatePassword}
      onClickLogout={handleLogout}
    />
  )
}

export default AccountPage
