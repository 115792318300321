import { useTexts } from '../../texts'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../constants'
import { MainPageLayout, MainPageContentLayout } from '../Layout'
import { Feedback } from '../components'
import coffeeImageAvif from '../../assets/images/coffee.avif'
import coffeeImageWebp from '../../assets/images/coffee.webp'
import coffeeImagePng from '../../assets/images/coffee.png'

const picture = {
  width: 418,
  height: 367,
  source: {
    avif: coffeeImageAvif,
    webp: coffeeImageWebp,
    fallback: coffeeImagePng,
  },
  styles: {
    maxWidth: '230px',
    height: 'auto',
  },
}

const Error = ({ error, woPicture }) => {
  const texts = useTexts()

  const defaultErrorProps = {
    button: {
      text: texts.getUnexpectedErrorCTA(),
      to: document.location.pathname,
    },
    secondaryButton: {
      text: texts.getUnexpectedErrorSecondaryButton(),
      to: AUTHENTICATED_DEFAULT_ROUTE,
    },
    picture,
  }

  const notFoundErrorProps = {
    title: texts.getNotFoundErrorTitle(),
    content: [texts.getNotFoundErrorSubtitle()],
    button: {
      text: texts.getNotFoundErrorCTA(),
      to: AUTHENTICATED_DEFAULT_ROUTE,
    },
    picture,
  }

  const unexpectedErrorProps = {
    ...defaultErrorProps,
    title: texts.getUnexpectedErrorTitle(),
    content: [texts.getUnexpectedErrorSubtitle()],
  }

  const networkErrorProps = {
    ...defaultErrorProps,
    title: texts.getNetworkErrorTitle(),
    content: [texts.getNetworkErrorSubtitle()],
  }

  const unauthorizedErrorProps = {
    title: texts.getUnauthorizedErrorTitle(),
    content: [texts.getUnauthorizedErrorSubtitle()],
    picture,
  }

  const viewProps =
    error.status === 401
      ? unauthorizedErrorProps
      : error.status === 404
      ? notFoundErrorProps
      : error.status === 503
      ? networkErrorProps
      : unexpectedErrorProps

  return (
    <MainPageLayout>
      <MainPageContentLayout>
        <Feedback
          {...viewProps}
          {...(woPicture ? { picture: undefined } : {})}
        />
      </MainPageContentLayout>
    </MainPageLayout>
  )
}

export default Error
