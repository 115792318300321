import { useLocation } from 'react-router-dom'

import * as a9 from '../../../../analytics'
import { useNavigateWithSearchParams } from '../../../utils'
import useForgottenPasswordFlow from './useForgottenPasswordFlow'
import View from './View'

const ForgottenPassword = () => {
  const { state } = useLocation()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const forgottenPassword = useForgottenPasswordFlow(state?.loginEmail)

  const redirectToLogin = () => {
    if (state?.from === 'access') {
      navigateWithSearchParams('/access', {
        state: { loginEmail: forgottenPassword?.userEmail ?? '' },
      })
    } else {
      navigateWithSearchParams('/login')
    }
  }

  const handleGoToLogin = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_GO_TO_LOGIN)
    redirectToLogin()
  }

  const handleResetPasswordSuccess = () => {
    redirectToLogin()
  }

  const handleReturnToFirstStep = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_GO_TO_FP_RESEND_CODE)
    forgottenPassword.handleReturnToFirstStep()
  }

  const handleCancle = () => {
    a9.dispatchEvent(a9.EVENTS_OLD.LEAVE_FORGOTTEN_PASSWORD, {
      stepName: forgottenPassword.stepsLayoutProps.stepName,
    })

    navigateWithSearchParams('/')
  }

  return (
    <View
      {...forgottenPassword}
      onCancel={handleCancle}
      onGoToLogin={handleGoToLogin}
      onContinueToSecondStep={forgottenPassword.handleContinueToSecondStep}
      onReturnToFirstStep={handleReturnToFirstStep}
      onResetPasswordSuccess={handleResetPasswordSuccess}
    />
  )
}

export default ForgottenPassword
