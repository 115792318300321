import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button } from '@mui/material'

import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import LogoutDialog from './LogoutDialog'

const styles = {
  width: 'var(--button-max-width)',
  alignSelf: 'center',
  mx: 'auto',
}

const LogoutPropTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClickLogout: PropTypes.func,
}

const Logout = ({ variant = 'primary', onClickLogout }) => {
  const texts = useTexts()
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  return (
    <>
      <LogoutDialog
        isOpen={showLogoutDialog}
        onCancel={() => setShowLogoutDialog(false)}
        onClickLogout={onClickLogout}
      />
      <Button
        sx={styles}
        id='logout'
        color={variant}
        onClick={() => setShowLogoutDialog(true)}
        startIcon={<Icons.SignOut />}
        fullWidth
      >
        {texts.getUserLogoutAction()}
      </Button>
    </>
  )
}
Logout.propTypes = LogoutPropTypes

export default Logout
