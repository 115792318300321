import { User } from '../../../../business'
import AssociatedMediaDynamicList from './AssociatedMediaDynamicList'
import { PageContent, WhiteBckgFullWidth } from '../../../Layout'
import { PAGE_CONTENT_MARGINS } from '../../../Layout/PageContent'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import HeroPartner from './HeroPartner'
import Interests from './Interests'
import Magazines from './Magazines'

const Discovery = () => {
  const promotionState = User.usePromotion()

  return (
    <View
      partnerData={{ ...promotionState, data: promotionState.data?.partner }}
    />
  )
}

export const View = ({ partnerData, withoutAddToHomeWizard }) => {
  return (
    <PageContent sx={{ mt: 0 }}>
      <WhiteBckgFullWidth sx={{ py: PAGE_CONTENT_MARGINS.mt }}>
        <HeroPartner partnerData={partnerData} />
        <Search />
      </WhiteBckgFullWidth>
      <Interests partnerData={partnerData} />
      <AssociatedMediaDynamicList />
      {!Boolean(partnerData?.data?.configuration?.woMagazines) && <Magazines />}
      {!withoutAddToHomeWizard && <AddToHomeScreenWizard />}
      <Sections />
    </PageContent>
  )
}

export default Discovery
