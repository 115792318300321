import { useLocation, Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { Session, User } from '../business'
import { CircularProgress } from './components'

const RequireActiveBonus = ({ children }) => {
  let location = useLocation()
  const promotionState = User.usePromotion()

  if (promotionState.isLoading) return <CircularProgress />
  if (
    location.pathname !== '/end-of-service' &&
    promotionState.isEndOfService
  ) {
    return <Navigate to='/end-of-service' state={{ from: location }} replace />
  }
  return children
}

const RequireAuthenticated = ({ children }) => {
  let location = useLocation()
  const [isRefreshingSession, setIsRefreshingSession] = useState(true)

  Session.storeSearchParam(Session.STORED_URL_PARAMS.BONUS)

  useEffect(() => {
    ;(async () => {
      if (Session.isAuthenticated() && Session.isExpirationClose()) {
        await Session.refreshSession()
        setIsRefreshingSession(false)
      }
      setIsRefreshingSession(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isRefreshingSession) return <CircularProgress />
  if (!Session.isAuthenticated()) {
    return <Navigate to='/' state={{ from: location }} replace />
  }
  return (
    <User.PromotionProvider>
      <RequireActiveBonus>{children}</RequireActiveBonus>
    </User.PromotionProvider>
  )
}

export default RequireAuthenticated
