import { useLocation, Navigate } from 'react-router-dom'

import { Session } from '../business'
import { AUTHENTICATED_DEFAULT_ROUTE } from './constants'
import { getSearchParamValue, SEARCH_PARAMS } from './searchParams'

const RequireNotAuthenticated = ({ children }) => {
  let location = useLocation()

  Session.storeSearchParam(Session.STORED_URL_PARAMS.BONUS)
  Session.storeSearchParam(Session.STORED_URL_PARAMS.PARTNER_ID)

  if (Session.isAuthenticated()) {
    const redirectUriValue = getSearchParamValue(SEARCH_PARAMS.REDIRECT_URI)
    try {
      if (redirectUriValue) {
        window.location = `${redirectUriValue}?${new URLSearchParams(
          Session.getAccessParams(),
        ).toString()}`
      }
    } catch (e) {}
    return (
      <Navigate
        to={AUTHENTICATED_DEFAULT_ROUTE}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

export default RequireNotAuthenticated
