import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import * as a9 from '../../../analytics'
import { openMediaLink } from '../../utils'
import { User } from '../../../business'
import { ArticlesList } from '../../components'

const TopicPage = () => {
  const { topicId } = useParams()

  const fetchArticlesByTopic = useMemo(
    () => async (pagination) =>
      await User.getArticlesForTopic(topicId, pagination),
    [topicId],
  )

  const articlesData = User.useArticlesList({
    fetchFn: fetchArticlesByTopic,
    withLastPageValidationHack: true,
  })

  const handleOpenArticle = (article) => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_ARTICLE, {
      interface: 'interest',
      articleId: article.articleId,
      mediaId: article.mediaProviderId,
    })

    openMediaLink(article.url, document.location.pathname)
  }

  return (
    <ArticlesList
      name='interest'
      onClickArticle={handleOpenArticle}
      {...articlesData}
    />
  )
}

export default TopicPage
