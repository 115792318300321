import { useNavigate, useParams } from 'react-router-dom'

import * as a9 from '../../../../analytics'
import { openMediaLink } from '../../../utils'
import { User } from '../../../../business'
import View from './View'

const Library = () => {
  const navigate = useNavigate()
  const { activeTabName } = useParams()

  const readArticlesData = User.useArticlesList({
    fetchFn: User.getArticlesViewedByUser,
    withLastPageValidationHack: true,
  })

  const favoriteArticlesData = User.useArticlesList({
    fetchFn: User.getUserFavoriteArticles,
  })

  const handleOpenArticle = (article, tabName) => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_ARTICLE, {
      interface: `tab_${tabName}`,
      articleId: article.articleId,
      mediaId: article.mediaProviderId,
    })
    openMediaLink(article.url, document.location.pathname)
  }

  const handleAnalyticsEventOnClickTab = (tabName) => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_TAB, {
      tabName,
    })
  }

  const handleChangeTab = (newTabName) => {
    handleAnalyticsEventOnClickTab(newTabName)
    navigate(`/library/${newTabName}`)
  }

  return (
    <View
      activeTabName={activeTabName}
      onOpenArticle={handleOpenArticle}
      onChangeTab={handleChangeTab}
      readArticlesData={readArticlesData}
      favoriteArticlesData={favoriteArticlesData}
    />
  )
}

export default Library
