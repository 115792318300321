import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

import * as a9 from '../../../../analytics'
import { Session, Public } from '../../../../business'
import { useNavigateWithSearchParams } from '../../../utils'
import useSearchParams from '../useSearchParams'
import useAccessLoad from '../useAccessLoad'
import useAccessFinish from '../useAccessFinish'
import { EMAIL_STEP_VARIANT } from './EmailStep/View'

const DEFATULT_VARIANT = EMAIL_STEP_VARIANT.STANDARD

const useAccess = () => {
  const { state } = useLocation()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const paramsData = useSearchParams()
  const accessLoad = useAccessLoad(paramsData)
  const accessFinish = useAccessFinish(paramsData)

  const [loginEmail, setLoginEmail] = useState(state?.loginEmail)
  const [registerEmail, setRegisterEmail] = useState()
  const [variant, setVariant] = useState(DEFATULT_VARIANT)
  const [partner, setPartner] = useState()

  const handleGoToForgottenPassword = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_GO_TO_FP)
    navigateWithSearchParams('/forgotten-password', {
      state: { from: 'access', loginEmail },
    })
  }

  const handleCloseButton = () => {
    a9.dispatchEvent(a9.EVENTS.PA_CLICK_CANCEL)
    accessFinish.handleFinishUnauth('User cancelled the process')
  }

  const handleFinishAuth = ({ userData, isNewUser }) => {
    Session.storeSession(userData)
    accessFinish.handleFinishAuth({ isNewUser })
  }

  const setReferredRegistrationError = () => {
    setVariant(EMAIL_STEP_VARIANT.PARTNER_REGISTRATION_ERROR)
    a9.dispatchEvent(a9.EVENTS.PA_PAGE_VIEW_REFERRED_REGISTRATION_ERROR)
  }

  const handleSubmitEmail = ({ isNewUser, email }) => {
    if (isNewUser) {
      if (!paramsData?.isReferred) {
        setReferredRegistrationError()
      } else {
        setRegisterEmail(email)
      }
    } else {
      setLoginEmail(email)
    }
  }

  useEffect(() => {
    ;(async () => {
      const setSuitableEmailStepVariant = async () => {
        setPartner()

        if (!!state?.referredRegistrationError) {
          setReferredRegistrationError()
        } else {
          if (!paramsData?.isReferred) {
            setVariant(EMAIL_STEP_VARIANT.PARTNER_MANDATORY_WARNING)
          } else if (variant.name !== EMAIL_STEP_VARIANT.PROMOTION) {
            const partner = await Public.getPartnerById(
              paramsData.registrationParams.partner_id,
            )
            if (partner) {
              setVariant(EMAIL_STEP_VARIANT.PROMOTION)
              setPartner(partner)
            }
          }
        }
      }

      setSuitableEmailStepVariant()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData])

  return {
    unauthenticationIsConfirmedInLoad: accessLoad.unauthenticationIsConfirmed,
    handleCloseButton,
    registerEmail,
    loginEmail,
    resetAccess: () => setRegisterEmail(),
    handleSubmitEmail,
    handleFinishAuth,
    handleGoToForgottenPassword,
    attribution: paramsData?.attribution ?? {},
    variant,
    partner,
  }
}

export default useAccess
