import { useNavigate } from 'react-router-dom'

import * as a9 from '../../../../../analytics'
import View from './View'

const Interests = ({ partnerData }) => {
  const navigate = useNavigate()
  const interests = partnerData?.data?.topics

  const handleClickTopic = (topic) => {
    a9.dispatchEvent(a9.EVENTS.PD_TOPIC_OF_INTEREST_CLICK, {
      topicId: topic.id,
    })
    navigate(`topic/${topic.id}/${encodeURIComponent(topic.name)}`, {
      state: {
        topicName: `${Boolean(topic.emoji) ? topic.emoji + ' ' : ''}${
          topic.name
        }`,
      },
    })
  }

  if (partnerData.isLoading || !Boolean(interests?.length)) {
    return null
  }
  return (
    <View
      interests={interests}
      onClickTopic={handleClickTopic}
      onRenderInterests={() => {
        a9.dispatchEvent(a9.EVENTS.PD_TOPIC_OF_INTEREST_SHOW)
      }}
    />
  )
}

export default Interests
