import { Session } from '../business'
import * as config from '../config'

const pushDataLayerVariable = (varName, varValue) =>
  window.gtag({ [varName]: varValue })

const pushDataLayerUserSubVariable = (value) =>
  pushDataLayerVariable('userSub', value)
const pushDataLayerVersionVariable = () =>
  pushDataLayerVariable('appVersion', config.appVersion)

document.addEventListener('appLoad', () => {
  pushDataLayerVersionVariable()
  const userSub = Session.getUserSub()
  if (Boolean(userSub)) {
    pushDataLayerUserSubVariable(userSub)
  }
})

document.addEventListener('sessionStored', () => {
  pushDataLayerUserSubVariable(Session.getUserSub())
})
