const NAVIGATION_HEIGHT = '56px'

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  navRoot: {
    height: NAVIGATION_HEIGHT,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: 'neutral.light',
    boxShadow: 4,
    p: 0,
  },
  navContent: {
    width: { xs: '100%', md: 'var(--container-max-width)' },
    maxHeight: '100%',
    py: 'var(--spacing-s)',
    mx: { xs: 0, md: 'auto' },

    display: 'flex',
    alignItems: 'center',
    columnGap: 1,

    '& h2': {
      display: 'inline',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  navBackButton: {
    m: 0,
    p: 0,
    height: 'var(--size-buttonM)',
    width: 'var(--size-buttonM)',
    color: 'neutral.darkest',
    '& > svg': {
      height: 'var(--size-iconM)',
      width: 'var(--size-iconM)',
    },
  },
  main: {
    flexGrow: 2,
    width: 'min(100%, var(--content-L-max-width))',
    mx: 'auto',
    mt: NAVIGATION_HEIGHT,

    '& > .secondary-page-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      width: { xs: 'calc(100% - 2.5rem)', md: '100%' },
      mx: 'auto',
      my: 'var(--spacing-l)',
      mt: 'var(--spacing-xl)',
    },
    '& h4': {
      mb: 'var(--spacing-m)',
      color: 'neutral.black',
    },
  },
}

export default styles
