import { useEffect, useState } from 'react'

import * as a9 from '../../../analytics'
import { useNavigateWithSearchParams } from '../../utils'
import { Public } from '../../../business'
import { CircularProgress } from '../../components'
import { ACCESS_PARAMS } from '../AccessPages/useSearchParams'
import useMediaButtons from '../useMediaButtons'

import View from './View'

const getUrlPartnerId = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get(ACCESS_PARAMS.PARTNER_ID)
}

const getMediaListForPartner = (partner, newspapers, magazines) => {
  if (!!partner?.configuration?.woMagazines) {
    return newspapers
  } else {
    return newspapers.concat(magazines)
  }
}

const LandingPartner = () => {
  const partnerId = getUrlPartnerId()
  const navigateWithSearchParams = useNavigateWithSearchParams()

  const { coreNewspapers, coreMagazines } = useMediaButtons({ partnerId })

  const [partner, setPartner] = useState()

  const handleClickContinue = () => {
    a9.dispatchEvent(a9.EVENTS.PP_CLICK_LANDING_PARTNER_CONTINUE, {
      partnerId,
    })
    navigateWithSearchParams('/access')
  }

  useEffect(() => {
    ;(async () => {
      const redirectToAccess = () => navigateWithSearchParams('/access')
      if (!partnerId) {
        redirectToAccess()
      } else {
        const partnerData = await Public.getPartnerById(partnerId)
        if (!partnerData || !Boolean(partnerData?.imageUrl)) {
          redirectToAccess()
        } else {
          setPartner(partnerData)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!partner) return <CircularProgress />
  return (
    <View
      media={getMediaListForPartner(partner, coreNewspapers, coreMagazines)}
      partner={partner}
      onClickContinue={handleClickContinue}
    />
  )
}

export default LandingPartner
