import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import * as a9 from '../../../../analytics'
import { openMediaLink } from '../../../utils'
import { User } from '../../../../business'
import { ArticlesList } from '../../../components'

const SectionPage = () => {
  const { sectionId } = useParams()

  const fetchArticlesBySection = useMemo(
    () => async (pagination) =>
      await User.getArticlesBySection(sectionId, pagination),
    [sectionId],
  )

  const articlesData = User.useArticlesList({
    fetchFn: fetchArticlesBySection,
    withLastPageValidationHack: true,
  })

  const handleOpenArticle = (article) => {
    a9.dispatchEvent(a9.EVENTS.PD_CLICK_ARTICLE, {
      interface: 'topic',
      articleId: article.articleId,
      mediaId: article.mediaProviderId,
    })
    openMediaLink(article.url, document.location.pathname)
  }

  return (
    <ArticlesList
      name={`section-${sectionId}`}
      onClickArticle={handleOpenArticle}
      {...articlesData}
    />
  )
}

export default SectionPage
