import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  Box,
  Link,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Decorator,
} from '../../../../components'
import { Icons } from '../../../../../assets'
import { useTexts } from '../../../../../texts'
import SocialLoginButtons from '../../SocialLoginButtons'
import styles from '../access.styles'

export const EMAIL_STEP_VARIANT = {
  STANDARD: 'STANDARD',
  PROMOTION: 'PROMOTION',
  PARTNER_MANDATORY_WARNING: 'PARTNER_MANDATORY_WARNING',
  PARTNER_REGISTRATION_ERROR: 'PARTNER_REGISTRATION_ERROR',
}

const emailStepViewPropTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onClickGoogle: PropTypes.func.isRequired,
  onClickApple: PropTypes.func.isRequired,
  withoutSocialLogin: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(EMAIL_STEP_VARIANT)),
}

const MailTo = ({ children }) => (
  <Link href={`mailto:${children}`}>{children}</Link>
)

const Title = ({ variant, partnerName, customClaim }) => {
  const texts = useTexts()

  switch (variant) {
    case EMAIL_STEP_VARIANT.PROMOTION:
      return (
        <Typography
          sx={styles.headingText}
          dangerouslySetInnerHTML={{
            __html:
              customClaim ?? texts.getAccessWithPromotionTitle(partnerName),
          }}
        />
      )
    case EMAIL_STEP_VARIANT.PARTNER_MANDATORY_WARNING:
      return (
        <Typography
          sx={styles.headingText}
          dangerouslySetInnerHTML={{
            __html: texts.getAccessWithInvitationWarning(),
          }}
        />
      )
    case EMAIL_STEP_VARIANT.PARTNER_REGISTRATION_ERROR:
      return (
        <Box sx={styles.headingText}>
          <Alert variant='error' text={texts.getAccessWithInvitationError1()} />
          <br></br>
          <Typography>
            {texts.getAccessWithInvitationError2()}
            <MailTo>info@bepayper.com</MailTo>
            {texts.getAccessWithInvitationError3()}
          </Typography>
        </Box>
      )
    default:
      return (
        <Typography id='dialogTitle' sx={styles.heading}>
          {texts.getAccessTitle()}
        </Typography>
      )
  }
}

const EmailStepView = ({
  formId,
  variant = EMAIL_STEP_VARIANT.STANDARD,
  partnerName,
  customClaim,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onClickGoogle,
  onClickApple,
  withoutSocialLogin,
}) => {
  const texts = useTexts()
  const isRegistrationError =
    variant === EMAIL_STEP_VARIANT.PARTNER_REGISTRATION_ERROR

  return (
    <>
      <Title
        variant={variant}
        partnerName={partnerName}
        customClaim={customClaim}
      />
      {!Boolean(withoutSocialLogin) && (
        <>
          <SocialLoginButtons
            onClickGoogle={onClickGoogle}
            onClickApple={onClickApple}
            disabled={isSubmitting || isRegistrationError}
          />
          <Decorator />
        </>
      )}
      <form id={formId} onSubmit={onSubmit} noValidate>
        <FormSkinWrapper skin='light' sx={styles.form}>
          <TextField
            id='email'
            name='email'
            label={texts.getLoginFormNameLabel()}
            type='email'
            autoComplete='email'
            InputProps={{
              readOnly: isRegistrationError,

              startAdornment: (
                <InputAdornment position='start'>
                  <Icons.Envelope />
                </InputAdornment>
              ),
            }}
            helperText={fieldErrors.email}
            error={Boolean(fieldErrors.email)}
            inputProps={{ inputMode: 'email' }}
            required
          />
          {Boolean(formError) ? (
            <Alert variant='error' text={formError} />
          ) : null}
          <LoadingButton
            color='primary'
            type='submit'
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting || isRegistrationError}
            loadingIndicator={<CircularProgress size={16} />}
            sx={styles.ctaBicolorLabel}
          >
            {texts.getContinueLabel()}
          </LoadingButton>
        </FormSkinWrapper>
      </form>
    </>
  )
}

EmailStepView.propTypes = emailStepViewPropTypes

export default EmailStepView
