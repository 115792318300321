import { useRef, useEffect } from 'react'

const ExpandableContent = ({ children, expanded }) => {
  const contentRef = useRef(null)

  useEffect(() => {
    if (expanded) {
      contentRef.current.style.maxHeight =
        contentRef.current.scrollHeight + 'px'
      contentRef.current.style.opacity = '1'
      contentRef.current.style.visibility = 'visible'
    } else {
      contentRef.current.style.maxHeight = '0'
      contentRef.current.style.opacity = '0'
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.style.visibility = 'hidden'
        }
      }, 500)
    }
  }, [expanded])

  return (
    <div
      ref={contentRef}
      style={{
        maxHeight: 0,
        overflow: 'hidden',
        opacity: 0,
        visibility: 'hidden',
        transition: 'max-height 0.5s ease-in-out, opacity 0.3s ease-in-out',
      }}
    >
      {children}
    </div>
  )
}

export default ExpandableContent
