const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--spacing-ml)',
  },
  button: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'neutral.light',
    },
    '&.not-expanded': {
      mt: 'calc(var(--spacing-ml) * -1)',
    },
  },
}

export default styles
