import { backofficeEndpoint } from '../../config'

import { InvalidUserError } from '../user'

import {
  UsernameAlreadyExistsError,
  CodeMismatchError,
  ExpiredCodeError,
  InvalidCredentialsError,
} from './errors'

export const REGISTRATION_TYPOLOGY = {
  STANDARD: 'standard',
  IDP: 'federated-identity-provider',
}
export const getRegistrationTypologyForEmail = async (email) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/email-registration-typology/${email}`,
  )

  if (response.ok) {
    return await response.text()
  } else {
    return null
  }
}

export const registerAndAutoLogin = async (userData, attribution) => {
  const response = await fetch(`${backofficeEndpoint}/auth/access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...userData,
      attribution,
    }),
  })

  if (response.ok && response.status === 201) {
    return await response.json()
  }

  if (!response.ok && response.status === 400) {
    const error = await response.json()

    if (error.code === 'UsernameExistsException') {
      throw new UsernameAlreadyExistsError()
    }
  }

  throw new Error('Something went wrong')
}

export const login = async (email, password) => {
  const loginData = { email, password }

  const response = await fetch(`${backofficeEndpoint}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginData),
  })
  if (response.ok && response.status === 200) {
    return await response.json()
  }

  if (!response.ok && response.status === 403) {
    const error = await response.json()

    if (error.code === 'InvalidUserException') {
      throw new InvalidUserError()
    }
  }

  if (!response.ok && response.status === 404) {
    const error = await response.json()

    if (error.code === 'InvalidCredentialsException') {
      throw new InvalidCredentialsError()
    }
  }

  throw new Error('Something went wrong')
}

export const register = async (userData, attribution) => {
  const response = await fetch(`${backofficeEndpoint}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...userData,
      attribution,
    }),
  })

  if (response.ok && response.status === 201) {
    return response.json().then((data) => data.sub)
  }

  if (!response.ok && response.status === 400) {
    const error = await response.json()

    if (error.code === 'UsernameExistsException') {
      throw new UsernameAlreadyExistsError()
    }
  }
  throw new Error('Something went wrong')
}

export const verifyCode = async (email, code) => {
  const response = await fetch(`${backofficeEndpoint}/auth/verification-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, code }),
  })

  if (response.ok && response.status === 204) {
    return Promise.resolve()
  }

  if (!response.ok && response.status === 400) {
    const error = await response.json()

    if (error.code === 'CodeMismatchException') {
      throw new CodeMismatchError()
    }
    if (error.code === 'ExpiredCodeException') {
      throw new ExpiredCodeError()
    }
  }
  throw new Error('Something went wrong')
}

export const resendCode = async (email) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/resend-verification-code`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  )

  if (response.ok && response.status === 204) {
    return Promise.resolve()
  }

  throw new Error('Something went wrong')
}

export const refreshSession = async (email, refreshToken) => {
  if (!email || !refreshToken) return null

  const response = await fetch(`${backofficeEndpoint}/auth/refresh-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Identity: refreshToken,
    },
    body: JSON.stringify({ email }),
  })
  if (response.ok && response.status === 200) {
    return await response.json()
  }
  return null
}

export const verifyCompleteRegistration = async (accessToken) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/complete-registration-verification`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )

  if (response.ok) {
    return await response.json()
  }
  throw new Error('something went wrong')
}

export const registerIdpAccountInTransactionalDB = async (
  { idToken, accessToken },
  attribution,
) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/third-party-idp-register`,
    {
      method: 'POST',
      headers: {
        Identity: idToken,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ attribution }),
    },
  )

  if (response.ok && response.status === 201) {
    return response.json()
  }

  throw new Error('something went wrong')
}

export const removeIdpAccountInCognito = async ({ idToken, accessToken }) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/third-party-idp-register`,
    {
      method: 'DELETE',
      headers: {
        Identity: idToken,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (response.ok) {
    return
  }

  throw new Error('something went wrong')
}

export const initForgottenPassword = async (email) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/init-forgotten-password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  )

  if (response.ok && response.status === 204) {
    return Promise.resolve()
  }
  const error = await response.json()

  throw new Error(error.message)
}

export const confirmForgottenPassword = async (
  email,
  verificationCode,
  newPassword,
) => {
  const response = await fetch(
    `${backofficeEndpoint}/auth/confirm-forgotten-password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, verificationCode, newPassword }),
    },
  )

  if (response.ok && response.status === 204) {
    return Promise.resolve()
  }

  const error = await response.json()
  if (!response.ok && response.status === 400) {
    if (error.code === 'CodeMismatchException') {
      throw new CodeMismatchError()
    }
    if (error.code === 'ExpiredCodeException') {
      throw new ExpiredCodeError()
    }
  }
  throw new Error(error.message)
}

export const authenticateRaceUser = async (memberNumber, token) => {
  const response = await fetch(`${backofficeEndpoint}/auth/race-access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ memberNumber, token }),
  })

  if (response.ok) {
    return await response.json()
  }

  if (!response.ok && response.status === 401) {
    throw new InvalidCredentialsError()
  }

  throw new Error('Something went wrong')
}
