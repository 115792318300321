// export const ROUTER_PATHS = []
export const AUTHENTICATED_DEFAULT_ROUTE = '/discovery'

export const NAVIGATION_ITEMS = {
  ACCOUNT: 'account',
  DISCOVERY: 'discovery',
  LIBRARY: 'library',
}

export const ASSOCIATED_MEDIA_DATA = [
  {
    id: 'b4f9cc2f-92b1-4cb4-b888-3fdff5c0b90b',
    name: 'El Mundo',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_mundo.svg',
    domain: 'https://www.elmundo.es/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '11610783-cecc-462f-ba69-68f066b9fa2f',
    name: 'El País',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_pais.svg',
    domain: 'https://elpais.com',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '499368dc-6385-48d8-84ed-3f0936502c1e',
    name: 'ABC',
    imageUrl:
      'https://s1.abcstatics.com/narwhal/1.2.184/assets/images/logo-ABC.svg',
    domain: 'https://www.abc.es',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '92b378e2-74cf-4133-9df5-a7384cceca0b',
    name: 'La Vanguardia',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/la-vanguardia-vector-logo.svg',
    domain: 'https://www.lavanguardia.com',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'a035d49f-bbf3-46e4-8595-64a1d7bdae28',
    name: 'El Español',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/el_espanol.svg',
    domain: 'https://www.elespanol.com/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'c137bedf-82af-4377-b740-8ae66e7db4bd',
    name: 'Cinco días',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/cinco_dias.svg',
    domain: 'https://cincodias.elpais.com',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'f4355a7c-12b4-41d2-9f7d-85316ff5a427',
    name: 'Expansión',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/expansion.svg',
    domain: 'https://www.expansion.com/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
    hasLowVisibility: true,
  },
  {
    id: 'a035d49f-bbf3-46e4-8595-64a1d7bdae28_invertia',
    name: 'Invertia',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/invertia.svg',
    domain: 'https://www.elespanol.com/invertia/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'ba0d8fbb-793e-413c-8f53-e7d7eb2c7d19',
    name: 'Marca',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/Marca.svg',
    domain: 'https://www.marca.com/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'e2c7d0c5-5775-42bf-8f6e-f18b9f511337',
    name: 'El Diario Vasco',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/logo_diariovasco.svg',
    domain: 'https://www.diariovasco.com',
    isCoreInMobile: true,
    isCoreInDesktop: false,
  },
  {
    id: '7cccc4fd-2da0-4139-8f0d-c58801639fa4',
    name: 'El Correo',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/logo-elcorreo.svg',
    domain: 'https://www.elcorreo.com',
    isCoreInMobile: false,
    isCoreInDesktop: false,
  },
]

export const MAGAZINES_DATA = [
  {
    id: 'b4f9cc2f-92b1-4cb4-b888-3fdff5c0b90b_yodona',
    name: 'Yo Dona',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/yodona.svg',
    domain: 'https://www.elmundo.es/yodona.html',
    isCoreInMobile: true,
    isCoreInDesktop: true,
    hasLowVisibility: true,
  },
  {
    id: 'b4f9cc2f-92b1-4cb4-b888-3fdff5c0b90b_papel',
    name: 'Papel',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/papel.svg',
    domain: 'https://www.elmundo.es/papel.html',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'b4f9cc2f-92b1-4cb4-b888-3fdff5c0b90b_metropoli',
    name: 'Metropoli',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/metropoli.svg',
    domain: 'https://www.elmundo.es/metropoli.html',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '11610783-cecc-462f-ba69-68f066b9fa2f_babelia',
    name: 'Babelia',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/babelia.svg',
    domain: 'https://elpais.com/babelia/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '11610783-cecc-462f-ba69-68f066b9fa2f_smoda',
    name: 'S Moda',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/smoda.svg',
    domain: 'https://elpais.com/smoda/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
    hasLowVisibility: true,
  },
  {
    id: '11610783-cecc-462f-ba69-68f066b9fa2f_icon',
    name: 'Icon',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/icon.svg',
    domain: 'https://elpais.com/icon/',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: '92b378e2-74cf-4133-9df5-a7384cceca0b_magazine',
    name: 'Magazine',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/magazine.svg',
    domain: 'https://www.lavanguardia.com/magazine',
    isCoreInMobile: true,
    isCoreInDesktop: true,
  },
  {
    id: 'f4355a7c-12b4-41d2-9f7d-85316ff5a427_fuera_de_serie',
    name: 'Fuera de serie',
    imageUrl:
      'https://s3.eu-west-1.amazonaws.com/www.bepayper.com/medios/fuera_de_serie.svg',
    domain: 'https://www.expansion.com/fueradeserie.html',
    isCoreInMobile: true,
    isCoreInDesktop: true,
    hasLowVisibility: true,
  },
]
