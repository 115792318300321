import { useEffect } from 'react'
import PropTypes from 'prop-types'

import * as a9 from '../../../../../analytics'
import usePasswordForm from './usePasswordForm'
import View from './View'

const FORM_ID = a9.EVENTS.PA_FORM_SUBMIT_LOGIN

const passwordStepPropTypes = {
  email: PropTypes.string.isRequired,
  onGoToForgottenPassword: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
}

const PasswordStep = ({ email, onGoToForgottenPassword, onContinue }) => {
  const { status, submitForm } = usePasswordForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  useEffect(() => {
    if (status.result !== null) {
      onContinue(status.result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    a9.dispatchEvent(a9.EVENTS.PA_PAGE_VIEW_LOGIN)
  }, [])

  return (
    <View
      email={email}
      formId={FORM_ID}
      onSubmit={handleSubmit}
      {...status}
      onGoToForgottenPassword={onGoToForgottenPassword}
    />
  )
}

PasswordStep.propTypes = passwordStepPropTypes

export default PasswordStep
