import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const mediaLogoPropTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  sx: PropTypes.object,
}

const MediaLogo = ({ data, sx }) => {
  return (
    <Box sx={sx} className='logo' key={data.id}>
      <img src={data.imageUrl} alt={data.name} />
    </Box>
  )
}

MediaLogo.propTypes = mediaLogoPropTypes

export default MediaLogo
