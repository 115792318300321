export const SEARCH_PARAMS = {
  REDIRECT_URI: 'redirect_uri',
}

export const getSearchParamsObject = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const obj = {}
  for (const [key, value] of searchParams.entries()) {
    Object.assign(obj, { [key]: value })
  }
  return obj
}

export const getSearchParamValue = (paramName) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(paramName)
}

export const removeSearchParamFromURL = (paramName) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const paramValue = urlSearchParams.get(paramName)

  if (paramValue !== null) {
    urlSearchParams.delete(paramName)

    // NOTE: Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${
      window.location.hash
    }`
    window.history.replaceState(null, '', newUrl)
  }
}

export const deleteSearchParams = () => {
  const newUrl = new URL(window.location)
  newUrl.search = ''
  window.location = newUrl
}
