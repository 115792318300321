const styles = {
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 'var(--spacing-l)',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

export default styles
