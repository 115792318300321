import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import styles from './list.styles'

const shortenedListPropTypes = {
  children: PropTypes.node,
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
}

const ShortenedList = ({ children, cta }) => {
  return (
    <Box sx={styles.root}>
      {children}
      {Boolean(cta) && (
        <Button fullWidth sx={styles.button} onClick={cta.onClick}>
          {cta.label}
        </Button>
      )}
    </Box>
  )
}

ShortenedList.propTypes = shortenedListPropTypes

export default ShortenedList
