import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import { formatNumber } from '../../../format'
import { Card } from '../../../components'

const LOW_WARNING_THRESHOLD = 0.5

const getVariablePropsOnBalance = (balance, texts) => {
  if (parseFloat(balance) === 0) {
    return {
      variant: 'light_error',
      renderContent: () => <Typography>{texts.noBalance()}</Typography>,
    }
  } else if (parseFloat(balance) <= LOW_WARNING_THRESHOLD) {
    return {
      variant: 'dark_error',
      renderContent: () => (
        <Typography
          dangerouslySetInnerHTML={{
            __html: texts.lowBalance(),
          }}
        />
      ),
    }
  }

  return {}
}

const getVariableProps = (balance, onClickTopUp, texts) => {
  const variableProps = {
    variant: 'dark',
  }

  if (Boolean(onClickTopUp)) {
    variableProps.actions = {
      primary: {
        label: texts.getBalanceTopUpAction(),
        onClick: onClickTopUp,
      },
    }
  }

  return {
    ...variableProps,
    ...getVariablePropsOnBalance(balance, texts),
  }
}

const balanceCardPropTypes = {
  balance: PropTypes.string.isRequired,
  onClickTopUp: PropTypes.func,
}

const BalanceCard = ({ balance, onClickTopUp }) => (
  <Card
    type='balance'
    title={formatNumber(balance)}
    {...getVariableProps(balance, onClickTopUp, useTexts())}
  />
)

BalanceCard.propTypes = balanceCardPropTypes

export default BalanceCard
