import { useEffect } from 'react'
import PropTypes from 'prop-types'

import * as a9 from '../../../../../analytics'
import { browser } from '../../../../utils'
import * as PT from '../../../../propTypes'
import useEmailForm from './useEmailForm'
import { default as View, EMAIL_STEP_VARIANT } from './View'

const FORM_ID = a9.EVENTS.PA_FORM_SUBMIT_EMAIL

const emailStepPropTypes = {
  onContinueLogin: PropTypes.func,
  onContinueRegister: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(EMAIL_STEP_VARIANT)),
  partner: PT.Partner,
}

const EmailStep = ({ onSubmitEmail, variant, partner }) => {
  const { status, submitForm } = useEmailForm(
    FORM_ID,
    partner?.configuration?.emailValidation,
  )

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  useEffect(() => {
    if (status.result !== null) {
      onSubmitEmail({
        isNewUser: status.result.isNewUser,
        email: status.formValues.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    a9.dispatchEvent(a9.EVENTS.PA_PAGE_VIEW_EMAIL)
  }, [])

  return (
    <View
      formId={FORM_ID}
      variant={variant}
      partnerName={partner?.name}
      customClaim={partner?.configuration?.claimAccess}
      onSubmit={handleSubmit}
      onClickGoogle={() => a9.dispatchEvent(a9.EVENTS.PA_CLICK_GOOGLE)}
      onClickApple={() => a9.dispatchEvent(a9.EVENTS.PA_CLICK_APPLE)}
      {...status}
      withoutSocialLogin={
        !!partner?.configuration?.emailValidation ||
        (browser.isInAppBrowser() && (browser.isAndroid() || browser.isIOS()))
      }
    />
  )
}

EmailStep.propTypes = emailStepPropTypes

export default EmailStep
