import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import Picture from '../Picture'
import styles from './feedback.styles'

const feedbackPropTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  secondaryButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  picture: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    source: PropTypes.shape({
      avif: PropTypes.node.isRequired,
      webp: PropTypes.node.isRequired,
      fallback: PropTypes.node.isRequired,
    }).isRequired,
    styles: PropTypes.object,
  }),
}

const Feedback = ({
  title,
  content = [],
  button,
  secondaryButton,
  picture,
}) => {
  const pictureStyles = picture?.styles ?? {}
  return (
    <Box aria-labelledby='feedback-title' sx={styles.root}>
      <Box sx={styles.content}>
        <Typography id='feedback-title' component='h1' sx={styles.title}>
          {title}
        </Typography>
        <Box sx={styles.text}>
          {content.map((text) => (
            <Typography key={text} sx={styles.textItem}>
              {text}
            </Typography>
          ))}
        </Box>
        {(Boolean(button) || Boolean(secondaryButton)) && (
          <Box sx={styles.buttons}>
            {Boolean(button) && (
              <Button
                fullWidth
                color='secondary'
                component={Link}
                to={button.to}
              >
                {button.text}
              </Button>
            )}
            {Boolean(secondaryButton) && (
              <Button
                fullWidth
                sx={styles.secondaryButton}
                component={Link}
                to={secondaryButton.to}
              >
                {secondaryButton.text}
              </Button>
            )}
          </Box>
        )}
        {Boolean(picture) && (
          <Box sx={{ ...styles.wrapImage, ...pictureStyles }}>
            <Picture
              width={picture.width}
              height={picture.height}
              {...picture.source}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

Feedback.propTypes = feedbackPropTypes

export default Feedback
