import { InputAdornment, Box, Button, Typography } from '@mui/material'

import { FormSkinWrapper, TextField, Alert } from '../../../components'
import { Icons } from '../../../../assets'
import { useTexts } from '../../../../texts'
import styles from './updatePasswordWithCode.styles'

const ConfirmSendCodeStep = ({ email, error, onClickSendCode }) => {
  const texts = useTexts()

  return (
    <Box className='secondary-page-content'>
      <FormSkinWrapper skin='light' sx={styles.wrapFields}>
        <Typography variant='subtitle1'>
          {texts.getUpdatePasswordWithCodeSecurityClaim()}
        </Typography>
        <TextField
          id='email'
          name='email'
          label={texts.getEmailLabel()}
          defaultValue={email}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position='start'>
                <Icons.Envelope />
              </InputAdornment>
            ),
          }}
          inputProps={{ inputMode: 'email' }}
        />
        {Boolean(error) && <Alert variant='error' text={error} />}
        <Box sx={styles.actions}>
          <Button color='primary' fullWidth onClick={onClickSendCode}>
            {texts.getForgottenPasswordSendCodeAction()}
          </Button>
        </Box>
      </FormSkinWrapper>
    </Box>
  )
}
export default ConfirmSendCodeStep
