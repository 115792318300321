import { createContext, useContext, useReducer, useEffect } from 'react'

import { User, Public, Session } from '../index'

const PromotionContext = createContext(null)

export const usePromotion = () => {
  const context = useContext(PromotionContext)
  if (context === null) {
    throw new Error('usePromotion must be used within a PromotionProvider')
  }
  return context
}

const PROMOTION_ACTIONS = {
  LOADING: 'LOADING',
  DATA: 'DATA',
  ERROR: 'ERROR',
}

const LOADING_STATE = {
  isLoading: true,
  data: undefined,
  error: false,
  isEndOfService: false,
}

const getIsEndOfService = ({ bonus, partner }) =>
  !Boolean(bonus) && Boolean(partner)

const promotionReducer = (state, action) => {
  switch (action.type) {
    case PROMOTION_ACTIONS.LOADING:
      return LOADING_STATE

    case PROMOTION_ACTIONS.DATA:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
        isEndOfService: getIsEndOfService(action.payload),
      }

    case PROMOTION_ACTIONS.ERROR:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: true,
        isEndOfService: false,
      }

    default:
      throw Error('Unknown action: ' + action.type)
  }
}

const PromotionProvider = ({ children }) => {
  const [promotionState, dispatchPromotionState] = useReducer(
    promotionReducer,
    LOADING_STATE,
  )

  const handleBonusCreation = async () => {
    let createdBonus = null
    const bonusTokenValue = Session.readStoredSearchParam(
      Session.STORED_URL_PARAMS.BONUS,
    )
    if (bonusTokenValue !== null) {
      try {
        const bonus = await User.createBonus(bonusTokenValue)
        if (!!bonus) {
          createdBonus = bonus
        }
      } catch (error) {
        console.error(
          'Payper: se ha producido un error durate la creación del bono',
        )
      }
    }
    return createdBonus
  }

  const fetchPromotionData = async () => {
    try {
      dispatchPromotionState({ type: PROMOTION_ACTIONS.LOADING })

      let bonus = await handleBonusCreation()
      if (bonus === null) {
        bonus = await User.fetchActiveBonus()
      }

      let partner = null
      if (bonus === null) {
        partner = await User.fetchLastPartner()
      } else {
        const partnerId = bonus?.detail?.partnerId
        if (!partnerId) {
          throw new Error(
            `Not found partnerId for bonus: ${JSON.stringify(bonus)}`,
          )
        }
        partner = await Public.getPartnerById(partnerId)
      }

      dispatchPromotionState({
        type: PROMOTION_ACTIONS.DATA,
        payload: { bonus, partner },
      })
    } catch (error) {
      dispatchPromotionState({ type: PROMOTION_ACTIONS.ERROR })
    }
  }

  useEffect(() => {
    ;(async () => await fetchPromotionData())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PromotionContext.Provider value={promotionState}>
      {children}
    </PromotionContext.Provider>
  )
}

export default PromotionProvider
