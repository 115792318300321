import { eventsEndpoint } from '../config'
import { Session } from '../business'
import { getCookie } from '../business/cookies'
import * as config from '../config'

const ANALYTICS_COOKIE = '_ga'

const getClientIdAnalyticsCookie = () => {
  const rawGaValue = getCookie(ANALYTICS_COOKIE)
  if (Boolean(rawGaValue)) {
    return rawGaValue.split('.').slice(-2).join('.')
  } else {
    return null
  }
}

const sendPayperEventList = async (eventList) => {
  const clientId = getClientIdAnalyticsCookie()
  if (clientId) {
    const response = await fetch(`${eventsEndpoint}/set-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: clientId,
        events: eventList,
      }),
    })
    if (!response.ok) {
      throw new Error()
    }
  }
}

const sendPayperEvent = async (eventName, detail) => {
  try {
    await sendPayperEventList([
      {
        name: eventName,
        params: detail,
      },
    ])
  } catch (error) {
    console.error(`Payper: error sending event ${eventName}`)
  }
}

export const dispatchEvent = (eventName, params = {}) => {
  sendPayperEvent(eventName, {
    ...params,
    userSub: Session.getUserSub() ?? undefined,
    version: config.appVersion,
  })
}

export * as EVENTS from './eventsNames.json'
export * as EVENTS_OLD from './eventsNamesOld.json'
